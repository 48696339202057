import { atom } from 'recoil';

export type IScreenRoutes =
	| 'palm-liveness-information'
	| 'palm-enroll'
	| 'palm-image';

export type IPalmMessage =
	| 'position-rectangular'
	| 'position-within-circle'
	| 'show-timer'
	| 'wrong-palm';

export const activePalmScreenState = atom<IScreenRoutes>({
	key: 'active-palm-screen-state-key',
	default: 'palm-liveness-information',
});

export const palmImageURL = atom<string>({
	key: 'palmImageURL',
	default: '',
});

export const IsPalmLoadedState = atom<boolean>({
	key: 'is-palm-modal-setup-state-key',
	default: false,
});

export const IsPalmDetected = atom<IPalmMessage>({
	key: 'is-palm-detected-state-key',
	default: 'position-rectangular',
});

export const IsPalmRetakeState = atom<boolean>({
	key: 'is-palm-retake-setup-state-key',
	default: false,
});

export const PalmEnrollCountdown = atom<number>({
	key: 'palm-enroll-countdown-state-key',
	default: -1,
});

export const PalmHandType = atom<string>({
	key: 'palm-hand-type-state-key',
	default: 'left',
});

export const palmMediaRecorder = atom<MediaRecorder | null>({
	key: 'palm-media-recorder-state-key',
	default: null,
});

export const ActivePalmHandTypeState = atom<string>({
	key: 'activ-palm-and-type-State-key',
	default: 'Left',
});

export const palmHandErrorCheck = atom<boolean>({
	key: 'palm-hand-error-check-key',
	default: false,
});
