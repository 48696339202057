import { useCallback, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import {
	REACT_APP_ENROLL_CUSTOM_SERVERS_API_URL,
	REACT_APP_ENROLL_CUSTOM_SERVERS_BEARER_TOKEN,
} from 'envs';
import { EnrollIceServerListState } from 'views/facial-enroll/components';

const useGenerateTurnCredentials = () => {
	const [data, setData] = useState<any>(null);
	const [error, setError] = useState<string | null>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const setEnrollIceServerList = useSetRecoilState(EnrollIceServerListState);

	const convertIceServers = useCallback((iceServers: any) => {
		const { urls, username, credential } = iceServers;
		return (urls ?? []).map((url: string) => {
			const server: Record<string, any> = { urls: [url] };
			if (url.startsWith('turn:') || url.startsWith('turns:')) {
				server.username = username ?? '';
				server.credential = credential ?? '';
			}
			return server;
		});
	}, []);

	const generateCredentials = async () => {
		setLoading(true);
		setError(null);
		try {
			const response = await fetch(REACT_APP_ENROLL_CUSTOM_SERVERS_API_URL, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${REACT_APP_ENROLL_CUSTOM_SERVERS_BEARER_TOKEN}`,
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ ttl: 86400 }),
			});

			if (!response.ok) {
				throw new Error(`Error: ${response?.status}`);
			}

			const result = await response.json();
			// Access iceServers from the result
			const iceServers = result?.iceServers ?? {};
			const convertedServers = convertIceServers(iceServers);
			setData(convertedServers);
			setEnrollIceServerList(convertedServers);
			return convertedServers;
		} catch (err: unknown) {
			if (err instanceof Error) {
				setError(err.message);
			} else {
				setError('An unknown error occurred');
			}
			return null;
		} finally {
			setLoading(false);
		}
	};

	return { data, error, loading, generateCredentials };
};

export default useGenerateTurnCredentials;
