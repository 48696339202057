import { useCallback, useEffect, useMemo } from 'react';

import {
	PlaidLinkOnExit,
	PlaidLinkOptions,
	usePlaidLink,
} from 'react-plaid-link';
import { useSetRecoilState } from 'recoil';
import { useBalanceCheck } from './store';
import { Loader } from '@storybook';
import { isShowSkipState } from 'states';

export const BalanceCheck = () => {
	const setIsShowSkip = useSetRecoilState(isShowSkipState);
	const {
		plaidSuccess: onSuccess,
		setLoader,
		loader,
		token,
		setToken,
	} = useBalanceCheck();

	//not required for now keep it for future use
	const onExit = useCallback<PlaidLinkOnExit>(() => {
		setLoader(false);
		setIsShowSkip(true);
		setToken(null as any);
	}, [setIsShowSkip, setLoader, setToken]);

	//plaid configuration
	const config: PlaidLinkOptions = {
		token,
		onSuccess,
		onExit,
	};

	// will be used in future as this is not required for now ready, error, exit , for now open is required only
	const { open } = usePlaidLink(config);

	useEffect(() => {
		if (token) {
			open();
		}
	}, [open, token]);

	const renderComponent = useMemo(() => {
		return (
			<Loader
				className="loader-blue"
				description={
					<div>
						{loader ? 'Fetching details with plaid' : 'Connecting to plaid'}
					</div>
				}
				dimension={48}
			/>
		);
	}, [loader]);

	return <div className="balance-check__wrapper">{renderComponent}</div>;
};
