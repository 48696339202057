import { useMemo } from 'react';
import { PalmEnroll } from './components/palm-camera/palm-enroll';
import { activePalmScreenState } from './states/palm-screen-state';
import { useRecoilValue } from 'recoil';
import { PalmImage } from './components/palm-image-detail/palm-image';
import { PalmLivenessInformation } from './components/palm-information/palm-information';

export const MainpalmEnrollment = () => {
	const activeScreen = useRecoilValue(activePalmScreenState);

	const renderMainComponent = useMemo(() => {
		switch (activeScreen) {
			case 'palm-liveness-information':
				return <PalmLivenessInformation />;
			case 'palm-enroll':
				return <PalmEnroll />;
			case 'palm-image':
				return <PalmImage />;
			default:
				return <></>;
		}
	}, [activeScreen]);

	return <div>{renderMainComponent}</div>;
};
