/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-use-before-define */

import { useCallback, useEffect, useRef } from 'react';
import {
	ActivePalmHandTypeState,
	IsPalmDetected,
	IsPalmLoadedState,
	PalmEnrollCountdown,
	PalmHandType,
	activePalmScreenState,
	palmHandErrorCheck,
	// activePalmScreenState,
	palmImageURL,
} from '../states/palm-screen-state';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { isMobileDevice } from 'utils';
import { distance2D } from 'utils/geometryUtils';
import { hasGetUserMedia } from 'utils/mediaUtils';
import { drawPlaceholder } from 'utils/canvasUtils';
// import { GestureRecognizerClass } from './GestureRecognizer';
import * as vision from '@mediapipe/tasks-vision';

const style = {
	lineColor: '#fff',
	lineWidth: 5,
};
const { FaceLandmarker, FilesetResolver, GestureRecognizer } = vision;
export const usePalm = () => {
	const faceLandmarker = useRef<any>();
	const webcamRunning = useRef(false);
	const gestureRecognizerResults = useRef<any>();
	const lastVideoTime = useRef(-1);
	// const results = useRef<any>();
	const canvasElement = useRef<HTMLCanvasElement>();
	const videoElement = useRef<HTMLVideoElement>();
	const videoBlendShapes = useRef<HTMLElement>();
	const animationFrameRef = useRef<number>(0);
	const isLeftRef = useRef(false);
	const handOffset = 60;
	// const videoWidth = window.innerWidth;
	const faceLandmarkerResults = useRef<any>();
	const gestureRecognizer = useRef<any>();
	const setPalmImageURL = useSetRecoilState(palmImageURL);
	const setPalmActiveScreen = useSetRecoilState(activePalmScreenState);
	const setPalmhModelLoaded = useSetRecoilState(IsPalmLoadedState);
	const setIsPalmDetected = useSetRecoilState(IsPalmDetected);
	const countdownRef = useRef<number>(-1);
	const setPalmCountDown = useSetRecoilState(PalmEnrollCountdown);
	const setPalmHandType = useSetRecoilState(PalmHandType);
	const countdownIntervalRef = useRef<NodeJS.Timeout | null>(null);
	const mediaRecorder = useRef<MediaRecorder | null>(null);
	const recordedChunks = useRef<Blob[]>([]);
	const activePalmHandType = useRecoilValue(ActivePalmHandTypeState);
	const setPalmHandErrorCheck = useSetRecoilState(palmHandErrorCheck);
	// const activeScreen = useRecoilValue(activePalmScreenState);

	const callbackFunc = useRef<any>();

	const updateModelFunction = useCallback(() => {
		setPalmhModelLoaded(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const disableCam = useCallback(() => {
		if (webcamRunning.current) {
			// Stop the video stream
			const stream = videoElement.current?.srcObject as MediaStream | null;
			if (stream) {
				const tracks = stream.getTracks();
				tracks.forEach(track => {
					track.stop();
				});
			}

			// Remove event listeners
			videoElement.current?.removeEventListener('loadeddata', drawCanvas);
			videoElement.current?.removeEventListener(
				'loadeddata',
				updateModelFunction
			);

			// Reset webcamRunning flag
			webcamRunning.current = false;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateModelFunction]);

	const handleDataAvailable = useCallback((event: BlobEvent) => {
		if (event.data.size > 0) {
			recordedChunks.current.push(event.data);
		}
	}, []);

	const startRecording = useCallback(() => {
		if (!mediaRecorder.current) return;

		if (mediaRecorder.current.state === 'recording') {
			// Stop the current recording before starting a new one
			mediaRecorder.current.stop();
			mediaRecorder.current.onstop = () => {
				recordedChunks.current = [];
				mediaRecorder.current?.start();
				// eslint-disable-next-line no-console
				console.log('MediaRecorder restarted');
			};
		} else {
			recordedChunks.current = [];
			mediaRecorder.current.start();
			// eslint-disable-next-line no-console
			console.log('MediaRecorder started');
		}
	}, []);

	const stopRecording = useCallback(() => {
		if (mediaRecorder.current && mediaRecorder.current.state !== 'inactive') {
			mediaRecorder.current.stop();
			// eslint-disable-next-line no-console
			console.log('MediaRecorder stopped');
			mediaRecorder.current.onstop = () => {
				const blob = new Blob(recordedChunks.current, { type: 'video/webm' });
				const reader = new FileReader();
				reader.onloadend = () => {
					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					const base64String = reader.result as string;
					//for Testing purpose only on Stage
					//eslint-disable-next-line no-console
					console.log('Recording:', base64String.split(',')[1]); // Logs the base64 string without the prefix
				};
				reader.readAsDataURL(blob);
			};
		}
	}, []);

	useEffect(() => {
		return () => {
			// Clean up on unmount or route change
			stopRecording();
		};
	}, [stopRecording]);

	const drawPalmImage = useCallback(
		(landmarks: any[]) => {
			if (!videoElement.current) {
				return;
			}

			if (!canvasElement.current) {
				return;
			}

			const handedness =
				gestureRecognizerResults.current?.handednesses?.[0]?.[0]?.displayName;

			const Points = {
				pinkyPip: 18,
				thumbTip: 6,
			};

			const isLeftHand = /right/gi.test(handedness ?? '');
			if (isLeftHand) {
				isLeftRef.current = true;
				// handedness = 'Left'
				Points.pinkyPip = 6;
				Points.thumbTip = 18;
			} else {
				isLeftRef.current = false;
			}

			const canvasWidth = canvasElement.current.width;
			const canvasHeight = canvasElement.current.height;

			let pinkyPipX = landmarks[Points.pinkyPip].x * canvasWidth - handOffset;
			let pinkyPipY = landmarks[Points.pinkyPip].y * canvasHeight - handOffset;

			let thumbTipX = landmarks[Points.thumbTip].x * canvasWidth + handOffset;
			let thumbTipY = landmarks[Points.thumbTip].y * canvasHeight + handOffset;

			const middleFingerPipX = landmarks[10].x * canvasWidth;
			const middleFingerPipY = landmarks[10].y * canvasHeight;

			let wristX = landmarks[0].x * canvasWidth + handOffset;
			let wristY = landmarks[0].y * canvasHeight + handOffset;

			const leftHandOffset = 80;
			if (isLeftHand) {
				pinkyPipX -= leftHandOffset;
				pinkyPipY -= leftHandOffset;
				thumbTipX -= leftHandOffset;
				thumbTipY -= leftHandOffset;
				wristX += leftHandOffset;
				wristY += leftHandOffset;
			}

			const distanceWidth = distance2D(
				pinkyPipX,
				pinkyPipY,
				thumbTipX,
				thumbTipY
			);
			const distanceHeight = distance2D(
				middleFingerPipX,
				middleFingerPipY,
				wristX,
				wristY
			);

			// Draw bounding box
			const bboxWidth = distanceWidth;
			const bboxHeight = distanceHeight;

			const offScreenCanvas = document.createElement('canvas');
			offScreenCanvas.width = bboxWidth;
			offScreenCanvas.height = bboxHeight;
			const offCtx = offScreenCanvas.getContext('2d');

			if (offScreenCanvas.width && offScreenCanvas.height) {
				// Draw the cropped area onto the off-screen canvas
				offCtx?.drawImage(
					videoElement.current,
					pinkyPipX,
					pinkyPipY,
					bboxWidth,
					bboxHeight,
					0,
					0,
					bboxWidth,
					bboxHeight
				);
				const canvas = document.getElementById(
					'palm_canvas'
				) as HTMLCanvasElement;
				const video = document.getElementById('palmEnroll') as HTMLVideoElement;
				if (canvas && video) {
					// cancelAnimationFrame(animationFrameRef.current);
					// animationFrameRef.current = 0;
					// webcamRunning.current = false;
					// // Display countdown
					// let countdown = 3;
					// Countdown finished, clear interval and set palmActiveScreen

					if (!countdownIntervalRef.current) {
						countdownRef.current = 3;
						startRecording();
						countdownIntervalRef.current = setInterval(() => {
							if (countdownRef.current > 0) {
								setPalmCountDown(countdownRef.current);
								countdownRef.current--;
							} else {
								const context = canvas.getContext('2d');
								context?.drawImage(video, 0, 0, canvas.width, canvas.height);
								// Convert the canvas content to a data URL
								const imageData = canvas.toDataURL('image/jpeg');
								setPalmImageURL(imageData);
								if (countdownIntervalRef.current)
									clearInterval(countdownIntervalRef.current);
								countdownIntervalRef.current = null;
								countdownRef.current = -1;
								setPalmActiveScreen('palm-image');
								disableCam();
								stopRecording();
							}
						}, 1000);
					}
				}
			}
		},
		[
			disableCam,
			setPalmActiveScreen,
			setPalmCountDown,
			setPalmImageURL,
			startRecording,
			stopRecording,
		]
	);

	const drawPalmCircle = useCallback(
		(landmarks: any[], style: any) => {
			const canvasCtx = canvasElement.current?.getContext('2d');

			if (!canvasCtx || !canvasElement.current) {
				return;
			}
			// Other existing code...
			const handedness =
				gestureRecognizerResults.current?.handednesses?.[0]?.[0]?.displayName;
			setPalmHandType(handedness);

			const canvasWidth = canvasElement.current.width;
			const canvasHeight = canvasElement.current.height;

			const wristX = landmarks[0].x * canvasWidth;
			const wristY = landmarks[0].y * canvasHeight;

			const topPalmStartX = landmarks[9].x * canvasWidth;
			const topPalmStartY = landmarks[9].y * canvasHeight;

			const palmCenterX = (topPalmStartX + wristX) / 2;
			const palmCenterY = (topPalmStartY + wristY) / 2;

			const centerX = canvasElement.current.width / 2;
			const centerY = canvasElement.current.height / 2;

			const mainRadius = distance2D(centerX, 0, centerX, centerY) / 2;
			// Adjust mainRadius for mobile devices
			//   if (window.innerWidth <= 600) {
			// 	mainRadius *= 0.5; // Reduce mainRadius by 20% for mobile devices
			// }
			const radius = distance2D(wristX, wristY, palmCenterX, palmCenterY) * 0.8;

			const openPalm = gestureRecognizerResults.current?.gestures.find(
				(gesture: any) => {
					return gesture?.[0].categoryName === 'Open_Palm';
				}
			);

			const offset = mainRadius / 2;
			const checkX =
				palmCenterX >= centerX - offset && palmCenterX <= centerX + offset;
			const checkY =
				palmCenterY >= centerY - offset && palmCenterY <= centerY + offset;

			const checkRadius = isMobileDevice()
				? radius <= mainRadius * 1.2 && radius >= mainRadius / 1.8
				: radius <= mainRadius * 2 && radius >= mainRadius / 2;

			// const checkRadius =
			// Math.abs(radius - mainRadius) <= 3;

			if (checkX && checkY && openPalm && checkRadius) {
				//style.lineColor = '#318CE7';
				style.lineColor = '#389F75';

				drawPalmImage(landmarks);

				canvasCtx.restore();
			} else {
				if (countdownIntervalRef.current)
					clearInterval(countdownIntervalRef.current);
				countdownIntervalRef.current = null;
				countdownRef.current = -1;
				setPalmCountDown(countdownRef.current);
				style.lineColor = '#fff';

				canvasCtx.beginPath();
				canvasCtx.arc(palmCenterX, palmCenterY, radius, 0, 2 * Math.PI, false);
				canvasCtx.lineWidth = isMobileDevice() ? 15 : 5;
				canvasCtx.strokeStyle = '#fff';
				canvasCtx.stroke();
			}
		},
		[drawPalmImage, setPalmCountDown, setPalmHandType]
	);

	const getCorrectPalmHand = () => {
		const handedness =
			gestureRecognizerResults.current?.handednesses?.[0]?.[0]?.displayName ??
			'';
		if (handedness === 'Left') {
			return 'Right';
		} else if (handedness === 'Right') {
			return 'Left';
		} else {
			return '';
		}
	};

	const drawCanvas = useCallback(async () => {
		const canvasCtx = canvasElement.current?.getContext('2d');
		const canvas = canvasElement.current;
		const video = videoElement.current;

		if (!canvasCtx || !video || !canvas) {
			return;
		}

		const desiredVideoWidth = '100vw';
		video.style.width = desiredVideoWidth + 'px';
		video.style.height = window.innerHeight + 'px';
		canvas.style.width = desiredVideoWidth + 'px';
		canvas.style.height = window.innerHeight + 'px';
		canvas.width = video.videoWidth;
		canvas.height = video.videoHeight;

		// Now let's start detecting the stream.
		const startTimeMs = performance.now();
		if (lastVideoTime.current !== video.currentTime) {
			lastVideoTime.current = video.currentTime;
			faceLandmarkerResults.current = faceLandmarker.current?.detectForVideo(
				video,
				startTimeMs
			);
			gestureRecognizerResults.current =
				gestureRecognizer.current?.recognize(video);
		}

		canvasCtx.clearRect(0, 0, canvas.width, canvas.height);

		if (!gestureRecognizerResults.current?.landmarks?.length) {
			style.lineColor = '#fff';
		}

		if (getCorrectPalmHand() === activePalmHandType) {
			setPalmHandErrorCheck(true);
			for (const landmarks of gestureRecognizerResults.current?.landmarks ??
				[]) {
				drawPalmCircle(landmarks, style);
			}

			if (gestureRecognizerResults.current?.landmarks?.length) {
				drawPlaceholder(style, canvas);
				setIsPalmDetected('position-within-circle');
			} else {
				setIsPalmDetected('position-rectangular');
			}
		} else {
			// eslint-disable-next-line no-console
			console.error('Wrong Palm detected');
			setIsPalmDetected('wrong-palm');
			setPalmHandErrorCheck(false);
		}

		callbackFunc.current?.(
			faceLandmarkerResults.current,
			gestureRecognizerResults.current
		);

		// Call this function again to keep predicting when the browser is ready.
		if (webcamRunning.current === true) {
			animationFrameRef.current = requestAnimationFrame(drawCanvas);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activePalmHandType, drawPalmCircle, setIsPalmDetected]);

	const enableCam = useCallback(
		async (draw?: boolean) => {
			if (webcamRunning.current) {
				// Webcam is already running, no need to enable it again.
				return;
			}
			if (!faceLandmarker.current) {
				return;
			}

			if (!hasGetUserMedia) {
				// eslint-disable-next-line no-console
				console.error('No camera access');
				return;
			}
			const idealSize = {
				width: { ideal: 1920 },
				height: { ideal: 1080 },
			};
			// getUsermedia parameters.
			const devices = await navigator.mediaDevices.enumerateDevices();

			let selectedCamera = devices.find(
				device =>
					device.kind === 'videoinput' &&
					device.label.toLowerCase().includes('back')
			);

			if (!selectedCamera) {
				// If back camera is not found, use any available video input device
				selectedCamera = devices.find(device => device.kind === 'videoinput');
			}

			const videoStream = {
				video: {
					deviceId: selectedCamera?.deviceId,
					facingMode: selectedCamera ? 'environment' : 'user', // Use "user" for front camera
					...idealSize,
				},
			};

			// Activate the webcam stream.
			navigator.mediaDevices.getUserMedia(videoStream).then(stream => {
				if (videoElement.current) {
					videoElement.current.srcObject = stream;
					webcamRunning.current = true;

					let options: any = {};
					// eslint-disable-next-line no-console
					console.log(options);

					if (MediaRecorder.isTypeSupported('video/webm; codecs=vp9')) {
						options = { mimeType: 'video/webm; codecs=vp9' };
					} else if (MediaRecorder.isTypeSupported('video/webm')) {
						options = { mimeType: 'video/webm' };
					} else if (MediaRecorder.isTypeSupported('video/mp4')) {
						options = { mimeType: 'video/mp4', videoBitsPerSecond: 100000 };
					} else {
						// eslint-disable-next-line no-console
						console.error('no suitable mimetype found for this device');
					}
					// Initialize MediaRecorder
					//const options = { mimeType: 'video/webm; codecs=vp9' };
					mediaRecorder.current = new MediaRecorder(stream, options);
					mediaRecorder.current.ondataavailable = handleDataAvailable;

					if (draw) {
						videoElement.current.addEventListener('loadeddata', drawCanvas);
						videoElement.current.addEventListener(
							'loadeddata',
							updateModelFunction
						);
					}
				}
			});
		},
		[handleDataAvailable, drawCanvas, updateModelFunction]
	);

	// Before we can use HandLandmarker class we must wait for it to finish
	// loading. Machine Learning models can be large and take a moment to
	// get everything needed to run.

	const start = useCallback(
		async ({
			video,
			canvas,
			blendShapes,
			draw = true,
			onResults,
		}: {
			video: HTMLVideoElement;
			canvas: HTMLCanvasElement;
			blendShapes?: HTMLElement | null;
			draw?: boolean;
			onResults?: (results?: any) => void;
		}) => {
			videoElement.current = video;
			canvasElement.current = canvas;
			// if (
			// 	!GestureRecognizerClass.FaceModel ||
			// 	!GestureRecognizerClass.PalmModel
			// ) {
			// 	return;
			// }
			if (blendShapes) {
				videoBlendShapes.current = blendShapes;
			}

			callbackFunc.current = onResults;

			const filesetResolver = await FilesetResolver.forVisionTasks(
				'https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.3/wasm'
			);

			faceLandmarker.current = await FaceLandmarker.createFromOptions(
				filesetResolver,
				{
					baseOptions: {
						modelAssetPath: `https://storage.googleapis.com/mediapipe-models/face_landmarker/face_landmarker/float16/1/face_landmarker.task`,
						delegate: 'GPU',
					},
					outputFaceBlendshapes: true,
					runningMode: 'VIDEO',
					numFaces: 1,
				}
			);
			gestureRecognizer.current = await GestureRecognizer.createFromOptions(
				filesetResolver,
				{
					baseOptions: {
						modelAssetPath:
							'https://storage.googleapis.com/mediapipe-models/gesture_recognizer/gesture_recognizer/float16/1/gesture_recognizer.task',
						delegate: 'GPU',
					},
					numHands: 2,
					runningMode: 'IMAGE',
				}
			);
			// faceLandmarker.current = GestureRecognizerClass.FaceModel;
			// gestureRecognizer.current = await GestureRecognizerClass.PalmModel;
			enableCam(draw);
		},
		[enableCam]
	);

	const draw = useCallback(async () => {
		await drawCanvas();
	}, [drawCanvas]);

	return { start, draw };
};
