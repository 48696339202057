import './link-expired.scss';
import { Image } from '@storybook';

export const LinkExpired = () => {
	return (
		<div className="LinkExpired--container">
			<Image
				fileName="Link_expired.svg"
				className="LinkExpired--container__image"
			/>
			<p className="LinkExpired--container__text-bold">
				Link has expired and is no longer valid
			</p>
			<p className="LinkExpired--container__text-regular">
				If you require access or need to proceed, please request a new
				invitation from the sender.
			</p>
		</div>
	);
};
