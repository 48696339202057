import { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useNetwork, useSharedVariables } from 'hooks';
import {
	BuisnessCompanyListState,
	BusinessInformationState,
	CountryStateList,
	CountryStateListState,
	IsBuisnessListFetchedState,
	KybBodyStepsState,
	SearchKybCompanyInputState,
	SelectedCompanyForKybState,
	TheKYBCompanyMemberForKycAmlVerificationListState,
	TheKYBCompanyMemberListState,
	TheKybSearchIntervalState,
} from 'views/kyb/stores';
import { APIS } from 'constants/api';

import useFetchWithToken from 'hooks/use-fetch-with-token/use-fetch-with-token';
import { customMember, THE_KYB_PROVIDER_TOKEN } from 'views/kyb/constants';

export const useTheKYBRequests = () => {
	// Hooks
	const { fetchData } = useFetchWithToken();
	const { envHost, sessionId } = useSharedVariables();

	// Recoils
	const businessInformation = useRecoilValue(BusinessInformationState);
	const [theKybSearchInterval, setTheKybSearchInterval] = useRecoilState(
		TheKybSearchIntervalState
	);
	const [kybBodyStep, setkybBodyStep] = useRecoilState(KybBodyStepsState);
	const [companiesList, setBuisnessCompanyList] = useRecoilState(
		BuisnessCompanyListState
	);

	const setIsBuisnessListFetched = useSetRecoilState(
		IsBuisnessListFetchedState
	);
	const selectedCompanyForKyb = useRecoilValue(SelectedCompanyForKybState);
	const setBuisnessCompanyMemberList = useSetRecoilState(
		TheKYBCompanyMemberListState
	);

	// this state is used for kyc-aml member
	const setKYBCompanyKycAmlMemberList = useSetRecoilState(
		TheKYBCompanyMemberForKycAmlVerificationListState
	);

	// this state is use for set the kyb company member search input
	const setSearchKybCompanyMembersInput = useSetRecoilState(
		SearchKybCompanyInputState
	);

	const [countryListResp, setCountryListResp] = useRecoilState(
		CountryStateListState
	);
	// local states
	const [loading, setLoading] = useState(false);
	const [memberLoading, setMemberLoading] = useState(false);

	// Refs
	const buisnessInformationRef = useRef(businessInformation);
	const theKybSearchIntervalRef = useRef(theKybSearchInterval);
	const kybBodyStepRef = useRef(kybBodyStep);

	useEffect(() => {
		buisnessInformationRef.current = businessInformation;
		theKybSearchIntervalRef.current = theKybSearchInterval;
		kybBodyStepRef.current = kybBodyStep;
	}, [businessInformation, kybBodyStep, theKybSearchInterval]);

	const { post: getCompanySearchId } = useNetwork();
	const { post: postCompanyMemberList } = useNetwork();

	const handleFindMyCompany = useCallback(
		(searchId: string) => {
			setkybBodyStep('kyb-loading-screen');
			let intervalId: NodeJS.Timeout | undefined;

			// eslint-disable-next-line prefer-const
			intervalId = setInterval(async () => {
				// clearing interval based on one check
				if (theKybSearchIntervalRef.current) {
					if (intervalId) {
						clearInterval(intervalId);
					}
					return;
				}
				const response = await fetchData(
					`${APIS.COMPANY_LIST_SEARCH}/${searchId}`,
					THE_KYB_PROVIDER_TOKEN[envHost as keyof typeof THE_KYB_PROVIDER_TOKEN]
				);
				if (
					response?.data?.status === 'completed' &&
					!theKybSearchIntervalRef.current
				) {
					setIsBuisnessListFetched(true);
					setBuisnessCompanyList(response?.data);
					setkybBodyStep('select_company');
					if (intervalId) {
						clearInterval(intervalId);
					}
					return;
				}
				if (
					response?.data?.companies?.length > 0 &&
					!theKybSearchIntervalRef.current
				) {
					setBuisnessCompanyList(response?.data);
					setkybBodyStep('select_company');
				}
				// Time Out condition
				if (response?.data?.status === 'timed_out') {
					setkybBodyStep('');
					if (intervalId) {
						clearInterval(intervalId);
					}
					return;
				}
			}, 5000); // Call fetchData every 4 seconds

			return () => {
				if (intervalId) {
					clearInterval(intervalId);
				}
			};
		}, // Cleanup the interval on unmount or when the status is completed
		[
			envHost,
			fetchData,
			setBuisnessCompanyList,
			setIsBuisnessListFetched,
			setkybBodyStep,
		]
	);

	const getSearchId = useCallback(async () => {
		setLoading(true);

		const { companyName, country, fein, state } =
			buisnessInformationRef.current;

		// Extract the company name with the country and state code
		const selectedCountry: CountryStateList = countryListResp.find(
			countries => countries.name === country
		) as CountryStateList;
		const getState = (selectedCountry?.states ?? []).find(
			item => item.name === state
		);

		const resp = await getCompanySearchId(
			APIS.COMPANY_LIST_SEARCH,
			{
				companyName,
				country: selectedCountry?.code,
				state: getState?.state_code,
				tin: fein?.replace(/[^0-9a-zA-Z]/g, '') ?? '',
			},
			'',
			{
				headers: {
					Authorization: `Bearer ${THE_KYB_PROVIDER_TOKEN[envHost as keyof typeof THE_KYB_PROVIDER_TOKEN]}`,
					'Content-Type': 'application/json',
				},
			}
		);
		if (resp?.statusCode === 400) {
			setkybBodyStep('');
		}
		if (resp?._id) {
			handleFindMyCompany(resp._id);
			setLoading(false);
		} else {
			setLoading(false);
		}
		setCountryListResp([]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		countryListResp,
		envHost,
		getCompanySearchId,
		handleFindMyCompany,
		setkybBodyStep,
	]);

	// *****
	const handleGetMembersData = useCallback(
		(searchId: string) => {
			if (!searchId) {
				return null;
			}
			let memberIntervalId: NodeJS.Timeout | undefined;
			// eslint-disable-next-line prefer-const
			memberIntervalId = setInterval(async () => {
				if (kybBodyStepRef.current === 'buisness_verification') {
					if (memberIntervalId) {
						clearInterval(memberIntervalId);
					}
					return;
				}
				const response = await fetchData(
					`${APIS.KYB_COMPANY_MEMBER}/${searchId}`,
					THE_KYB_PROVIDER_TOKEN[envHost as keyof typeof THE_KYB_PROVIDER_TOKEN]
				);
				if (response?.data?.status === 'completed') {
					setBuisnessCompanyMemberList(() => ({
						activeIndex: null,
						members: [...(response?.data?.members?.value ?? []), customMember],
					}));
					setSearchKybCompanyMembersInput('');
					setMemberLoading(false);
					setkybBodyStep('select_member');
					if (memberIntervalId) {
						clearInterval(memberIntervalId);
					}
					return;
				}
				// Time Out condition
				if (response?.data?.status === 'timed_out') {
					setBuisnessCompanyMemberList(() => ({
						activeIndex: null,
						members: [...(response?.data?.members?.value ?? []), customMember],
					}));
					setSearchKybCompanyMembersInput('');
					setMemberLoading(false);

					setkybBodyStep('select_member');
					if (memberIntervalId) {
						clearInterval(memberIntervalId);
					}
					return;
				}
			}, 5000); // Call fetchData every 5 seconds

			return () => {
				if (memberIntervalId) {
					clearInterval(memberIntervalId);
				}
			};
		}, // Cleanup the interval on unmount or when the status is completed
		[
			envHost,
			fetchData,
			setBuisnessCompanyMemberList,
			setkybBodyStep,
			setSearchKybCompanyMembersInput,
		]
	);

	const getCompanyMemberList = useCallback(async () => {
		setMemberLoading(true);
		setTheKybSearchInterval(true);
		const resp = await postCompanyMemberList(
			APIS.KYB_COMPANY_MEMBER,
			{
				companyId: selectedCompanyForKyb.id,
				requestId: companiesList._id,
				code: sessionId,
			},
			'',
			{
				headers: {
					Authorization: `Bearer ${THE_KYB_PROVIDER_TOKEN[envHost as keyof typeof THE_KYB_PROVIDER_TOKEN]}`,
					'Content-Type': 'application/json',
				},
			}
		);

		const { members } = resp ?? {};

		if (resp?.status === 'completed') {
			setBuisnessCompanyMemberList(() => ({
				activeIndex: null,
				members: [...members, customMember],
			}));

			/* mapping the company member data for kyc-aml member list */
			const selectedKycAmlMemberDetails = members
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				.map(({ ssnNumber, date_of_birth, ...member }: any) => ({
					...member,
				}));

			// set the company member for select the kyc-aml verification
			setKYBCompanyKycAmlMemberList(() => ({
				activeIndex: {},
				members: [...selectedKycAmlMemberDetails, customMember],
			}));
			setSearchKybCompanyMembersInput('');
			setMemberLoading(false);
			//Rahul singh: please don't remove for future use.
			setkybBodyStep('select_member');
			// setIsMultipleRepresentativeState(true);
		} else {
			// Call  every 5 seconds
			handleGetMembersData(members?.id);
		}
	}, [
		sessionId,
		setkybBodyStep,
		companiesList._id,
		envHost,
		handleGetMembersData,
		postCompanyMemberList,
		selectedCompanyForKyb.id,
		setBuisnessCompanyMemberList,
		setTheKybSearchInterval,
		setKYBCompanyKycAmlMemberList,
		setSearchKybCompanyMembersInput,
	]);

	return {
		getSearchId,
		loading,
		getCompanyMemberList,
		memberLoading,
	};
};
