import { Button, Image, Loader } from '@storybook';

import './face-setup.scss';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { CameraFaceModalLoaded } from 'views/facial-enroll/states';

export const FaceSetupModal = () => {
	const [cameraLoaded, setCameraFaceModalLoaded] = useRecoilState(
		CameraFaceModalLoaded
	);

	// Function for reload the page
	const reloadPage = useCallback(() => {
		location.reload();
		setCameraFaceModalLoaded(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<div className="FaceSetup__wrapper">
			<div className="FaceSetup__loading-wrapper">
				<Image fileName="Settings.svg" className="FaceSetup__loading-svg" />
				<p className="FaceSetup__loading-heading">
					{!cameraLoaded
						? `We are preparing to initiate facial authentication.`
						: 'looks like something went wrong while loading the modal'}
				</p>

				<div className="FaceSetup__loading-instructions">
					{!cameraLoaded
						? `Ensure you are in a well-lit environment. Stay close to the camera,
					but not too close.`
						: `Please try again or refresh the page. We appreciate your patience!`}
				</div>
				{!cameraLoaded ? (
					<Loader type="loader" className="loader-blue" dimension={30} />
				) : (
					<Button
						label={'Retry'}
						handleClick={reloadPage}
						type="button__filled button__filled--primary button__large "
					/>
				)}
			</div>
		</div>
	);
};
