export const convertToCurrencySystem = (amount: string | number) => {
	return Math.abs(Number(amount)) >= 1.0e9
		? Number((Math.abs(Number(amount)) / 1.0e9).toFixed(2)) + 'B'
		: Math.abs(Number(amount)) >= 1.0e6
			? Number((Math.abs(Number(amount)) / 1.0e6).toFixed(2)) + 'M'
			: Math.abs(Number(amount)) >= 1.0e3
				? Number((Math.abs(Number(amount)) / 1.0e3).toFixed(2)) + 'K'
				: Number(Math.abs(Number(amount))).toFixed(2);
};

export const isNumber = (number: string) => {
	const number_regex = /^(?:\d+(?:.\d*)?|.\d+)?$/;
	const isValid = number_regex.test(number);
	if (isValid) {
		return true;
	} else {
		return false;
	}
};
