export const CUSTOM_SERVERS = [
	{
		urls: 'turn:turn.cloudflare.com:3478?transport=udp',
		username:
			'ec96a52bf5a29e36ad40b865657fde03e083b6e40cf77aa427c0404644424115',
		credential:
			'448e57bdc259b90cdf7d8e0e67a5d5edcdee9cf031cc5c0e66aa7e74a33b2f33',
	},
	{
		urls: 'turn:turn.cloudflare.com:3478?transport=tcp',
		username:
			'ec96a52bf5a29e36ad40b865657fde03e083b6e40cf77aa427c0404644424115',
		credential:
			'448e57bdc259b90cdf7d8e0e67a5d5edcdee9cf031cc5c0e66aa7e74a33b2f33',
	},
	{
		urls: 'turns:turn.cloudflare.com:5349?transport=tcp',
		username:
			'ec96a52bf5a29e36ad40b865657fde03e083b6e40cf77aa427c0404644424115',
		credential:
			'448e57bdc259b90cdf7d8e0e67a5d5edcdee9cf031cc5c0e66aa7e74a33b2f33',
	},
	{
		urls: 'stun:stun.cloudflare.com:3478',
	},
];

export const VIDEO_SETTINGS = {
	video: {
		width: { ideal: 1280 },
		height: { ideal: 720 },
	},
	audio: false,
	facingMode: 'user',
};
