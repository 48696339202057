import {
	ISessionDetails,
	ISessionPayloadDetail,
	ISignAgreementProviderState,
} from './types';
import { atom } from 'recoil';

export const CurrentStepIndex = atom({
	key: 'current-step-index',
	default: 0,
});

export const CurrentActionIndex = atom({
	key: 'current-action-index',
	default: 0,
});
export const FundNameState = atom({
	key: 'fundName-name-state',
	default: '',
});

export const SessionDetailsState = atom<ISessionDetails>({
	key: 'session-Details-state',
	default: {
		_id: '',
		sessionId: '',
		userId: '',
		email: '',
		name: '',
		phone: '',
		steps: [],
		pipelineId: '',
		redirectUrl: 'https://example.com',
		investingAmount: null,
		noOfShares: null,
		sharePrice: null,
		fundName: '',
		nodes: null,
		existingKybFormAction: null,
		organization: {
			name: '',
			logo: '',
			colorScheme: {
				primary: '',
				secondry: '',
				background: '',
				text: '',
			},
		},
	},
});

export const SessionPayloadDetailState = atom<ISessionPayloadDetail>({
	key: 'session-payload-details-state',
	default: {
		_id: '',
		sessionId: '',
		userId: '',
		phone: '',
		email: '',
		name: '',
		investingAmount: null,
		noOfShares: null,
		sharePrice: null,
		fundName: '',
		stepsId: '',
		countryCode: '',
		sessionType: '',
		currentAction: {
			iframe: false,
			_id: '',
			label: '',
			key: '',
			value: [],
			status: '',
		},
		conditions: [],
	},
});

export const SignAgreementProviderState = atom<ISignAgreementProviderState>({
	key: 'sign-agreement-provider-state-key',
	default: 'esign',
});

export const faceEnrollmentEnabledState = atom<boolean>({
	key: 'face-enrollment-enabled-state-key',
	default: false,
});

export const PalmEnrollmentEnabledState = atom<boolean>({
	key: 'palm-enrollment-enabled-state-key',
	default: false,
});

export const boundingBoxCheck = atom<boolean>({
	key: 'bounding-box-enabled-state-key',
	default: false,
});
