import { Loader } from '@storybook';
import { useEffect, useState } from 'react';

export const KycLoader = () => {
	const maxCount = 45;
	const [countdown, setCountdown] = useState(maxCount);

	useEffect(() => {
		if (countdown === 0) {
			return;
		}
		const timer = setInterval(() => {
			setCountdown(prevCount => prevCount - 1);
		}, 1000);

		return () => clearInterval(timer);
	}, [countdown]);

	return (
		<div className="kyc-loader-wrapper">
			<Loader
				type="countdown"
				countdown={countdown}
				description={
					<>
						<div className="GifLoader--container__subHeading">Please Wait</div>
						<div className="GifLoader--container__heading">
							{countdown > 0
								? 'Processing your request'
								: 'It’s taking longer than usual'}
						</div>
					</>
				}
			/>
		</div>
	);
};
