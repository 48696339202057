import { useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import {
	useNextStep,
	useNotification,
	useSharedVariables,
	useTokenSession,
} from 'hooks';
import { AccessTokenState } from 'states';

export const useExistingSession = () => {
	// globle states
	const { code: sessionCode } = useRecoilValue(AccessTokenState);

	// hooks
	const {
		handleNext,
		sessionDetails,
		sessionPayloadDetail,
		setSessionDetails,
	} = useNextStep();
	const { errorNotification } = useNotification();
	const { onboardingType } = useSharedVariables();
	const { postTokenSession } = useTokenSession();

	const { currentAction, stepsId: step } = useMemo(
		() => sessionPayloadDetail ?? {},
		[sessionPayloadDetail]
	);

	const getExistingSession = useCallback(
		(step: string) => {
			const { existingSession, existingSessionNodeIds } = sessionDetails ?? {};
			return (
				existingSession?.[step as 'kyc'] ??
				existingSessionNodeIds?.[step as 'kyc'] ??
				null
			);
		},
		[sessionDetails]
	);
	const submitExistingSession = useCallback(async () => {
		const { id }: any = getExistingSession(step);
		if (id?.length > 0) {
			const payload = {
				...(onboardingType === 'complex'
					? {
							nodeId: currentAction?._id,
							existingSessionNode: {
								id: id ?? '',
								step,
							},
						}
					: {
							stepId: step,
							existingSession: {
								id: id ?? '',
								step,
							},
						}),
			};
			const resp = await postTokenSession({
				payload,
				code: sessionCode,
			});
			const { success, statusCode } = resp ?? {};

			if (success || statusCode === 200) {
				if (onboardingType === 'complex') {
					const response = { ...resp };
					delete response.statusCode;
					setSessionDetails(prev => ({
						...prev,
						nodes: response,
						steps: [],
					}));
				} else handleNext();
			} else {
				errorNotification('Something went wrong');
			}
			return resp;
		}
	}, [
		getExistingSession,
		step,
		onboardingType,
		currentAction?._id,
		postTokenSession,
		sessionCode,
		handleNext,
		setSessionDetails,
		errorNotification,
	]);

	return { submitExistingSession, getExistingId: getExistingSession };
};
