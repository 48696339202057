import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { ComponentRoot } from 'component-root';
import { AccessTokenState } from 'states';
import { UnauthorizedDomain, ErrorPage } from 'components';
import { FlowType } from 'constants/common';
import { useSharedVariables, useToken } from 'hooks';
import { useLocation } from 'hooks/location';
import { useNextStep } from 'hooks/use-next-step';
import { Main } from 'views/main';
import { PleaseWait } from 'views/please-wait';
import { ROUTES, STEPS } from './constant';
import { useTokenSession } from 'hooks/token/token-session';
import { checkHexaDecimal } from 'utils';
import { useGetLoationDetails } from 'hooks/location-details';

interface Props {
	onComplete: () => void;
	handleChange: () => void;
	code: string;
	steps?: string[];
	termsCondition?: boolean;
	biometric?: boolean;
	fromSignup?: boolean;
}

export const AllRoutes: React.FC<Props> = ({
	code,
	onComplete,
	handleChange,
	steps,
	biometric = true,
	termsCondition = true,
	fromSignup = false,
}) => {
	const requestStatusRef = useRef<number>(0);
	const [loading, setLoading] = useState(false);

	const { code: accessCode = '', token: accessToken } =
		useRecoilValue(AccessTokenState);
	const { locationInfo, fetchLocation } = useLocation();
	const { sessionPayloadDetail, setSessionDetails } = useNextStep();
	const { sessionId: componentId, onboardingType } = useSharedVariables();
	const { getGeoInfo } = useGetLoationDetails();

	const { generateToken } = useToken();
	const { postTokenSession } = useTokenSession();

	const getToken = async () => {
		setLoading(true);
		const { QR, Session } = FlowType;
		await generateToken(
			componentId || code,
			checkHexaDecimal(componentId || code) ? QR : Session
		);
		setLoading(false);
	};

	useEffect(() => {
		if (componentId || code) {
			getToken();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const {
		sessionId,
		_id: pipelineId,
		userId,
		stepsId,
	} = useMemo(() => sessionPayloadDetail ?? {}, [sessionPayloadDetail]);

	useEffect(() => {
		const shouldFetchLocation = (): boolean =>
			!Object.keys(locationInfo ?? {}).length;

		const isComplexOnboarding = (): boolean => onboardingType === 'complex';

		const shouldSubmitGeoLocation = (): boolean => {
			if (!accessToken || checkHexaDecimal(accessCode)) return false;
			if (requestStatusRef.current !== 0) return false;
			if (isComplexOnboarding()) return true;
			return !!sessionId;
		};

		const submitGeoLocationPayload = (): {
			geoLocation: any;
			pipelineId?: any;
		} => {
			const payload: { geoLocation: any; pipelineId?: any } = {
				geoLocation: locationInfo ?? {},
			};
			if (!isComplexOnboarding()) {
				payload.pipelineId = pipelineId;
			}
			return payload;
		};

		if (shouldFetchLocation()) {
			fetchLocation();
		} else if (shouldSubmitGeoLocation()) {
			requestStatusRef.current = 1;
			postTokenSession({ payload: submitGeoLocationPayload() });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		locationInfo,
		fetchLocation,
		postTokenSession,
		requestStatusRef,
		sessionPayloadDetail,
		sessionId,
		pipelineId,
		accessToken,
	]);

	const handleSessionPatch = useCallback(async () => {
		const resp = await getGeoInfo();
		const { ip } = resp ?? {};
		if (onboardingType === 'complex') {
			const resp = await postTokenSession({
				payload: {
					sessionId: pipelineId,
					userId,
					userAgent: true,
					stepId: 'term-condition',
					ipAddress: ip || undefined,
				},
			});

			if (resp?.success) {
				setSessionDetails(prev => ({ ...prev, steps: [] }));
			}
			return;
		}
		if (sessionId && userId) {
			await postTokenSession({
				payload: {
					sessionId,
					userId,
					userAgent: true,
				},
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sessionId, userId, pipelineId, onboardingType]);

	useEffect(() => {
		if (
			stepsId === STEPS.Facial_Recognition ||
			stepsId === STEPS.Authentication
		) {
			if (sessionId && userId) {
				handleSessionPatch();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sessionId]);

	useEffect(() => {
		const handleBrowserBackButton = (e: Event) => {
			window.history.pushState(null, '', window.location.href);
			e.preventDefault();
		};

		window.history.pushState(null, '', window.location.href);
		window.addEventListener('popstate', handleBrowserBackButton);

		return () => {
			window.removeEventListener('popstate', handleBrowserBackButton);
		};
	}, []);

	if (loading) {
		return <ComponentRoot componentKey="default" />;
	}

	if (accessCode === '' && !componentId) {
		return <ErrorPage status={500} />;
	}

	const props = {
		onComplete,
		handleChange,
		steps,
		biometric,
		termsCondition,
		fromSignup,
	};

	return (
		<Routes>
			<Route path={ROUTES.PLEASE_WAIT} element={<PleaseWait />} />
			<Route path={ROUTES.DEFAULT} element={<Main {...props} />} />
			<Route path="/" element={<Main {...props} />} />
			<Route path="*" element={<Navigate to="/" />} />
			<Route path={ROUTES.INVALID_ORIGIN} element={<UnauthorizedDomain />} />
		</Routes>
	);
};
