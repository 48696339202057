import { useCallback, useMemo } from 'react';
import { useNextStep, useSharedVariables, useTokenSession } from 'hooks';
import { useRecoilValue } from 'recoil';
import { AccessTokenState } from 'states';

export const useSuccessReuest = () => {
	const { sessionPayloadDetail, sessionDetails } = useNextStep();
	const { onboardingType, allowRedirect } = useSharedVariables();
	const { code: sessionCode } = useRecoilValue(AccessTokenState);
	const { postTokenSession } = useTokenSession();

	const {
		_id: pipelineId,
		stepsId,
		userId,
	} = useMemo(() => sessionPayloadDetail ?? {}, [sessionPayloadDetail]);

	const commonPayload = {
		pipelineId,
		userId,
		stepId: stepsId,
	};

	const handleRedirect = useCallback(() => {
		const isValidRedirectUrl = !/example.com/gi.test(
			sessionDetails.redirectUrl
		);
		if (isValidRedirectUrl && allowRedirect !== 'false') {
			window.location.href = sessionDetails.redirectUrl;
		}
	}, [allowRedirect, sessionDetails.redirectUrl]);

	const submitSuccess = async () => {
		const payload: any = {
			...commonPayload,
			actions: [
				{
					id: '',
					data: {},
				},
			],
		};
		if (onboardingType === 'complex') {
			// COMPLEX_SESSION patch api
			postTokenSession({ payload, code: sessionCode }).finally(() => {
				handleRedirect();
			});
			return;
		}
		// KYC_SESSION patch api
		postTokenSession({ payload, code: sessionCode }).finally(() => {
			handleRedirect();
		});
	};
	return { submitSuccess };
};
