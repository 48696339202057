export function distance2D(
	x1: number,
	y1: number,
	x2: number,
	y2: number
): number {
	return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
}

export function calculateCenterX(
	canvasWidth: number,
	isRightHand: boolean
): number {
	// Adjust centerX based on device type
	const centerX: number = canvasWidth / 2;

	// Define xOffset based on your requirements
	const xOffset: number = 100; // Adjust this value according to your design

	// Adjust centerX based on hand position
	if (isRightHand) {
		// Right hand, adjust to the right
		return centerX - xOffset;
	} else {
		// Left hand, adjust to the left
		return centerX + xOffset;
	}
}
