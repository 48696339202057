import { Button, Image, Loader } from '@storybook';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
	IsPalmLoadedState,
	IsPalmRetakeState,
	PalmEnrollCountdown,
	activePalmScreenState,
	palmImageURL,
} from 'views/palm-enroll/states/palm-screen-state';
import './palm-image.scss';
import { PalmImageDetail } from './constant/detail';
import { useNextStep, useSharedVariables } from 'hooks';
import { selfieImageURL } from 'views/facial-enroll/states/selfie-image-state';
import { SessionIdComplex, SubmitBtnLoad } from 'views/selfie-camera/store';
import { useEffect, useMemo } from 'react';
import { useFaceRecognitionRequests } from 'views/facial-enroll/hooks/hooks';

export const PalmImage = () => {
	const palmImage = useRecoilValue(palmImageURL);
	const setPalmActiveScreen = useSetRecoilState(activePalmScreenState);
	const setIsPalmMeshModelLoaded = useSetRecoilState(IsPalmLoadedState);
	const selfieImage = useRecoilValue(selfieImageURL);
	const { handleNext } = useNextStep();
	const sessionIdComplex = useRecoilValue(SessionIdComplex);
	const { onboardingType } = useSharedVariables();
	const { submitFaceRecognition } = useFaceRecognitionRequests();
	const setIsPalmRetakeState = useSetRecoilState(IsPalmRetakeState);
	const submitBtnLoad = useRecoilValue(SubmitBtnLoad);
	const setPalmCountDown = useSetRecoilState(PalmEnrollCountdown);
	const stopCamera = () => {
		const videoElement = document.getElementById('palmEnroll');

		if (videoElement instanceof HTMLVideoElement) {
			const stream = videoElement.srcObject;

			if (stream instanceof MediaStream) {
				const tracks = stream.getTracks();

				tracks.forEach(track => {
					track.stop();
				});

				videoElement.srcObject = null;
			}
		}
	};

	useEffect(() => {
		stopCamera();
	}, []);

	const handleSubmitPalmImage = () => {
		stopCamera();
		if (sessionIdComplex || onboardingType === 'complex') {
			const imageDataWithoutPrefix = selfieImage.replace(
				/^data:image\/jpeg;base64,/,
				''
			);
			submitFaceRecognition(imageDataWithoutPrefix);
		} else {
			handleNext();
		}
	};

	const renderSubmitLabel = useMemo(
		() => (submitBtnLoad ? <Loader type="loader" dimension={20} /> : 'Submit'),
		[submitBtnLoad]
	);

	return (
		<div className="palmDetail--container">
			<div className="palmDetail--container__content">
				<h2 className="palmDetail--container__content__heading">
					{PalmImageDetail.HEADING}
				</h2>
				<p className="palmDetail--container__content__subheading">
					{PalmImageDetail.DESCRIPTION}
				</p>
			</div>

			<Image className="palmDetail--container__imageinfo" url={palmImage} />
			<div className="palmDetail__button--info palmDetail__button--sticky">
				<Button
					label={renderSubmitLabel}
					type="button__filled button__filled--primary button__large button__block"
					handleClick={handleSubmitPalmImage}
				/>
				<Button
					label={`Retake`}
					type="button__filled button__filled--secondary button__large button__block"
					handleClick={() => {
						stopCamera();
						setPalmActiveScreen('palm-enroll');
						setIsPalmMeshModelLoaded(false);
						setIsPalmRetakeState(true);
						setPalmCountDown(-1);
					}}
				/>
			</div>
		</div>
	);
};
