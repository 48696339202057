import { Image, Loader } from '@storybook';

import './palm-setupModel.scss';

export const PalmSetupModal = () => {
	return (
		<div className="PalmSetup__wrapper">
			<div className="PalmSetup__loading-wrapper">
				<Image fileName="Settings.svg" className="PalmSetup__loading-svg" />
				<p className="PalmSetup__loading-heading">
					We are preparing to initiate Palm authentication.
				</p>

				<div className="PalmSetup__loading-instructions">
					Ensure you are in a well-lit environment. Stay close to the camera,
					but not too close.
				</div>
				<Loader type="loader" className="loader-blue" dimension={30} />
			</div>
		</div>
	);
};
