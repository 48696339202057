import { useCallback, useEffect, useMemo, useState } from 'react';
import { PlaidLinkOnSuccess } from 'react-plaid-link';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import {
	useNextStep,
	useNotification,
	useSharedVariables,
	useTokenSession,
} from 'hooks';
import { AccessTokenState, isShowSkipState } from 'states';
import { BalanceCheckPlaidToken } from './states';
import { useFundInvestmentRequests } from 'views/fund-investment/stores';

export const useBalanceCheck = () => {
	const { code: sessionCode } = useRecoilValue(AccessTokenState);
	const setIsShowSkip = useSetRecoilState(isShowSkipState);
	const plaidToken = useRecoilValue(BalanceCheckPlaidToken);

	const [token, setToken] = useState(plaidToken);
	const [loader, setLoader] = useState(false);

	const { sessionPayloadDetail, setSessionDetails } = useNextStep();
	const { onboardingType } = useSharedVariables();
	const { postTokenSession } = useTokenSession();
	const { fetchBankDetails, linkAccount } = useFundInvestmentRequests();
	const { errorNotification } = useNotification();

	const {
		stepsId,
		_id: pipelineId,
		userId,
		currentAction,
	} = useMemo(() => sessionPayloadDetail ?? {}, [sessionPayloadDetail]);

	useEffect(() => {
		if (plaidToken) {
			setToken(plaidToken);
		}
	}, [plaidToken]);

	const completeStep = useCallback(
		async (accountDetails: any) => {
			const { accounts, totalAvailableBalance, totalCurrentBalance } =
				accountDetails;
			const payload = {
				nodeId: currentAction?._id,
				actions: [
					{
						accounts,
						totalAvailableBalance,
						totalCurrentBalance,
					},
				],
			};

			if (onboardingType === 'complex') {
				// COMPLEX_SESSION patch api
				const res = await postTokenSession({
					payload,
					code: sessionCode,
				} as any);
				if (res?.statusCode === 200) {
					const signResponse = { ...res };
					delete signResponse.statusCode;
					setSessionDetails(prev => ({
						...prev,
						nodes: signResponse,
						fundName: res?.fundName ?? '',
						investingAmount: res?.investingAmount ?? '',
					}));
					return res;
				} else {
					setIsShowSkip(true);
				}
				return;
			}
		},
		[
			currentAction?._id,
			onboardingType,
			postTokenSession,
			sessionCode,
			setIsShowSkip,
			setSessionDetails,
		]
	);

	const plaidSuccess = useCallback<PlaidLinkOnSuccess>(
		async (publicToken, metadata) => {
			const linkAccountPayload = {
				pipelineId: pipelineId ?? '',
				stepId: stepsId,
				userId,
				actions: [
					{
						data: {
							token: publicToken,
							institutionName: metadata.institution?.name,
							linkToken: token,
						},
					},
				],
			};
			setLoader(true);
			linkAccount(linkAccountPayload)
				.then(() => {
					return fetchBankDetails();
				})
				.then(response => {
					return completeStep(response);
				})
				.catch(err => {
					errorNotification(err?.message ?? 'Failed to fetch balance');
					setIsShowSkip(true);
				})
				.finally(() => {
					setToken('');
					setLoader(false);
				});
		},
		[
			pipelineId,
			stepsId,
			userId,
			token,
			linkAccount,
			fetchBankDetails,
			completeStep,
			errorNotification,
			setIsShowSkip,
		]
	);

	return { completeStep, plaidSuccess, setLoader, loader, token, setToken };
};
