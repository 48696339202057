export const ExistSessionTable = {
	kyc: [
		{
			key: 'name',
			label: 'Name',
		},
		{
			key: 'email',
			label: 'Email',
		},
		{
			key: 'phone',
			label: 'Phone',
		},
		{
			key: 'doctype',
			label: 'Document type',
		},
		{
			key: 'docID',
			label: 'Document ID',
		},
		{
			key: 'date',
			label: 'Date of KYC',
		},
	],
	kyb: [
		{
			key: 'companyName',
			label: 'Company name',
		},
		{
			key: 'url',
			label: 'Website URL',
		},
		{
			key: 'streetAddress',
			label: 'Street address',
		},
		{
			key: 'city',
			label: 'City',
		},
		{
			key: 'state',
			label: 'State',
		},
		{
			key: 'country',
			label: 'Country',
		},
		{
			key: 'date',
			label: 'Phone',
		},
		{
			key: 'date',
			label: 'TIN',
		},
		{
			key: 'date',
			label: 'Date of KYB',
		},
	],
};

export const ChooseOptions = {
	kyc: [
		{
			key: 'existingSession',
			title: 'Continue with existing KYC session',
			subTitle: '',
			icon: 'ri-contacts-fill',
		},
		{
			key: 'newSession',
			title: 'Start new KYC session',
			subTitle: '',
			icon: 'ri-user-add-fill',
		},
	],
	kyb: [
		{
			key: 'existingSession',
			title: 'Continue with existing KYB session',
			subTitle: '',
			icon: 'ri-briefcase-fill',
		},
		{
			key: 'newSession',
			title: 'Start new KYB session',
			subTitle: '',
			icon: 'ri-building-fill',
		},
	],
};

export const ExistSessionHeadText = {
	kyc: {
		title: 'KYC session exists',
		subTitle1: "It looks like you've completed a KYC session within the last ",
		subTitle2:
			'.You have the option to continue with the existing session or start a new one.',
	},
	kyb: {
		title: 'KYB session exists',
		subTitle1: "It looks like you've completed a KYB session within the last ",
		subTitle2:
			'.You have the option to continue with the existing session or start a new one.',
	},
};
