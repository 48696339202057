import { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { Loader } from '@storybook';

import { BodyWrapper, LabelElement } from 'components';
import {
	ChooseOptions,
	ExistSessionHeadText,
	ExistSessionTable,
	ExistingSessionsState,
	useExistingSession,
} from './stores';

import './exist-session.scss';
import { WebComponentMetaDataState } from 'states';

interface IExistSession {
	step: 'kyc' | 'kyb';
}

export const ExistSession = ({ step = 'kyc' }: IExistSession) => {
	//globle state
	const resetIsExist = useResetRecoilState(ExistingSessionsState);
	const webComponentMetaData = useRecoilValue(WebComponentMetaDataState);

	//local state
	const [isSessionUpdate, setIsSessionUpdate] = useState(false);

	// hooks
	const { submitExistingSession, getExistingId } = useExistingSession();

	const stepLabel = useMemo(() => step?.toUpperCase() ?? '', [step]);

	const { title, subTitle1, subTitle2 } = ExistSessionHeadText[step] ?? {};
	const { interval }: any = getExistingId(step) ?? {};

	const { fromSignup = false } = useMemo(
		() => webComponentMetaData,
		[webComponentMetaData]
	);

	useLayoutEffect(() => {
		if (fromSignup) return;

		const init = async () => {
			await submitExistingSession();
		};
		init();
	}, [fromSignup, submitExistingSession]);

	const headerElement = useMemo(() => {
		return (
			<div className="ExistSession__title-wrapper">
				<div className="ExistSession__title-wrapper--title">{title ?? ''}</div>
				<div className="ExistSession__title-wrapper--subtitle">
					{subTitle1 + interval + subTitle2}
				</div>
			</div>
		);
	}, [subTitle1, subTitle2, title, interval]);

	const renderExistingSessionDetails = useMemo(
		() => (
			<div className="ExistSession__sessionDetails">
				<div className="ExistSession__detailsTitle">
					Existing {stepLabel} session details :
				</div>
				<div className="ExistSession__detailsTable">
					{(ExistSessionTable[step] ?? []).map(({ key, label }) => (
						<div key={key} className="ExistSession__tableRow">
							<div className="ExistSession__tableKey">{label}</div>:
							<div className="ExistSession__tableValue">{label + ' Value'}</div>
						</div>
					))}
				</div>
			</div>
		),
		[step, stepLabel]
	);

	const handleClickOnOption = useCallback(
		async (key: string) => {
			if (key === 'newSession') {
				resetIsExist();
			} else {
				setIsSessionUpdate(true);
				await submitExistingSession();
				setIsSessionUpdate(false);
			}
		},
		[resetIsExist, submitExistingSession]
	);

	const renderChooseOptions = useMemo(
		() => (
			<div className="ExistSession__chooseOption">
				<div className="ExistSession__optionHeadTitle">
					Choose an option below :
				</div>
				<div className="ExistSession__list-wrapper">
					{(ChooseOptions[step] ?? [])?.map(
						({ key, title, subTitle, icon }) => {
							return (
								<div
									onClick={() => handleClickOnOption(key)}
									className="list-item"
									key={key}
								>
									<div className={'animate-in-2 ExistSession__iconImage'}>
										<i className={icon} />
									</div>
									{
										<div className="ExistSession__OptionWrapper">
											<div className="list-text animate-in-2 ExistSession__OptionTitle">
												{title}
											</div>
											<div className="list-text animate-in-2 ExistSession__OptionSubTitle">
												{subTitle}
											</div>
										</div>
									}
									<i
										className={`ri-arrow-right-s-line ${'animate-in-2 ExistSession__nextIcon'}`}
									></i>
								</div>
							);
						}
					)}
				</div>
			</div>
		),
		[handleClickOnOption, step]
	);

	const bodyElement = useMemo(() => {
		return (
			<div className="ExistSession__bodyWrapper">
				{false && renderExistingSessionDetails}
				{renderChooseOptions}
			</div>
		);
	}, [renderExistingSessionDetails, renderChooseOptions]);

	if (isSessionUpdate) {
		return (
			<div className="loading-steps">
				<Loader />
			</div>
		);
	}

	return (
		<>
			<BodyWrapper
				label={<LabelElement text={stepLabel} />}
				headerElement={headerElement}
				bodyElement={bodyElement}
			/>
		</>
	);
};
