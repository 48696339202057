import { useCallback, useMemo } from 'react';
import {
	useRecoilState,
	useRecoilValue,
	useResetRecoilState,
	useSetRecoilState,
} from 'recoil';
import { toast } from 'react-toastify';

import { APIS, API_TYPE } from 'constants/api';
import {
	useNetwork,
	useNextStep,
	useNotification,
	useSharedVariables,
	useUrl,
	useTokenSession,
} from 'hooks';

import {
	EntityAvoidOptions,
	accreditationTypes,
	finraLisenceDropDownDefaultValue,
	restrictedForNonOfAbow,
	restrictedKeys,
} from '../constants';
import {
	AccredQueAndAns,
	AccredSelectedKeyState,
	AccredUploadFileState,
	EntityAccredFormState,
	FinraUploadState,
	SelectedOptionState,
	SelectedUploadOptionKeyState,
} from './states';
import { AccessTokenState, InviteUserData, isShowSkipState } from 'states';
import {
	AccreditedByVerifierInputErrorState,
	AccreditedByVerifierState,
} from '../components';

export const useAccreditationRequests = () => {
	// globle state
	const [selectedOption, setSelectedOption] =
		useRecoilState(SelectedOptionState);
	const uploadedFiles = useRecoilValue(AccredUploadFileState);
	const accredQueAndAns = useRecoilValue(AccredQueAndAns);
	const finraUploadValue = useRecoilValue(FinraUploadState);
	const selectedkey = useRecoilValue(AccredSelectedKeyState);
	const formData = useRecoilValue(EntityAccredFormState);
	const setIsShowSkip = useSetRecoilState(isShowSkipState);
	const inviteUserData = useRecoilValue(InviteUserData);
	const { code: sessionCode } = useRecoilValue(AccessTokenState);
	const selectedUploadKey = useRecoilValue(SelectedUploadOptionKeyState);
	const resetSelectedUploadKey = useResetRecoilState(
		SelectedUploadOptionKeyState
	);
	const resetUploadedFiles = useResetRecoilState(AccredUploadFileState);
	const resetSelectedAllLabel = useResetRecoilState(AccredQueAndAns);

	const resetSelectedKey = useResetRecoilState(AccredSelectedKeyState);
	const resetAccreditedByVerifier = useResetRecoilState(
		AccreditedByVerifierState
	);
	const resetInputError = useResetRecoilState(
		AccreditedByVerifierInputErrorState
	);

	// hooks
	const { sessionPayloadDetail, setSessionDetails } = useNextStep();
	const { errorNotification } = useNotification();
	const { get } = useNetwork();
	const { code } = useUrl();
	const { onboardingType } = useSharedVariables();
	const { postTokenSession } = useTokenSession();

	const {
		_id: pipelineId,
		currentAction,
		stepsId,
		userId,
	} = useMemo(() => sessionPayloadDetail ?? {}, [sessionPayloadDetail]);

	const questionsAnswer = useMemo(
		() =>
			accredQueAndAns.filter((item: { isDeleted: boolean }) => !item.isDeleted),
		[accredQueAndAns]
	);

	const commonPayload = {
		pipelineId,
		stepId: stepsId,
		userId,
	};

	const type = (sessionPayloadDetail as any).currentAction.metadata
		?.accreditationType?.value;

	const { accreditationDocument, accreditationType } =
		currentAction?.metadata ?? {};
	const requestURL = async (onSuccess?: any) => {
		const payload: any = {
			stepId: 'accreditation',
			code: inviteUserData?.code ?? code,
			data: {
				type:
					selectedkey === 'entityAccreditationVerify' ||
					selectedkey === 'describeEntity'
						? 'entity'
						: 'individual',
				option: selectedOption ?? '1',
				entityName: formData.entityName,
				name: formData.name,
				title: formData.designation,
				describeEntity: formData.describe,
			},
		};
		if (onboardingType === 'complex') {
			delete payload.code;
			payload.nodeId = currentAction?._id;
		}
		// APIS.COMPLEX_REQUEST_TOKEN post api
		const res = await postTokenSession({
			...(onboardingType === 'complex' && { nodeId: currentAction?._id }),
			payload,
			type: API_TYPE.REQUEST_URL,
			code: sessionCode,
		});

		if (res && res.statusCode === 200) onSuccess(res?.url);
		else if (selectedkey !== EntityAvoidOptions.DescribeEntity) {
			setSelectedOption('1');
			errorNotification(res?.message ?? 'Something went wrong');
		}
	};

	const resetAll = useCallback(() => {
		resetAccreditedByVerifier();
		resetInputError();
		resetSelectedKey();
		resetSelectedUploadKey();
		resetUploadedFiles();
		resetSelectedAllLabel();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const submitAccred = async () => {
		const questionnaire =
			questionsAnswer.length > 0 &&
			questionsAnswer?.filter(
				(item: any) =>
					item.key !== 'entityAccreditationForm' &&
					item.key !== 'describeEntity'
			);

		if (onboardingType === 'complex') {
			const payload = {
				pipelineId,
				nodeId: currentAction._id,
				userId,
				actions: [
					{
						id: currentAction?.key,
						data: {
							accreditationDocument,
							accreditationType,
							key: selectedkey,
							questionnaire,
						},
					},
				],
			};
			//COMPLEX_SESSION patch api
			const res = await postTokenSession({ payload, code: sessionCode });

			if (res && res.statusCode === 200) {
				const acredResponse = { ...res };
				delete acredResponse.statusCode;
				setSessionDetails(prev => ({
					...prev,
					nodes: acredResponse,
				}));
				resetAll();
			} else {
				errorNotification(res.message ?? 'Something went wrong');
			}
			return;
		}

		const payload = {
			...commonPayload,
			actions: [
				{
					id: currentAction?.key,
					data: {
						accreditationType: type,
						key: selectedkey,
						questionnaire,
					},
				},
			],
		};
		// KYC_SESSION patch api
		const res = await postTokenSession({ payload, code: sessionCode });

		if (res && res.statusCode === 200) {
			resetAll();
			return;
		} else {
			setIsShowSkip(true);
			errorNotification(res.message ?? 'Something went wrong');
		}
	};

	const submitFindraAccreditation = async () => {
		const finrapayload = {
			...commonPayload,
			...(onboardingType === 'complex' && {
				nodeId: currentAction._id,
			}),
			actions: [
				{
					id: currentAction?.key,

					data: {
						...(onboardingType === 'complex'
							? {
									accreditationType,
									accreditationDocument,
								}
							: { accreditationType: type }),

						// key: key,
						questionnaire: questionsAnswer,
						license:
							finraUploadValue.license ?? finraLisenceDropDownDefaultValue,
						crdNumber: finraUploadValue.crdNumber,
					},
				},
			],
		};

		// APIS.COMPLEX_SESSION and APIS.KYC_SESSION patch api
		const res = await postTokenSession({
			payload: finrapayload,
			code: sessionCode,
		});

		if (res && res.statusCode === 200) {
			const finraResp = { ...res };
			delete finraResp.statusCode;
			setSessionDetails(prev => ({ ...prev, nodes: finraResp }));
			resetAll();
			return;
		} else {
			setIsShowSkip(true);
			errorNotification(res.message ?? 'Something went wrong');
		}
	};

	const submitUploadDocuments = async () => {
		const files =
			uploadedFiles
				?.filter(item => item?.selectedKey === selectedUploadKey)
				?.map(item => item.base64Image) ?? [];
		const payload: any = {
			...commonPayload,
			actions: [
				{
					id: currentAction?.key,
					data: {
						accreditationDocument,
						accreditationType,
						files,
						questionnaire: questionsAnswer,
					},
				},
			],
		};
		if (onboardingType === 'complex') {
			payload.nodeId = currentAction._id;
		}
		const res = await toast.promise(
			// APIS.COMPLEX_SESSION and APIS.KYC_SESSION patch api
			postTokenSession({ payload, code: sessionCode }),

			{
				pending: '....Uploading File',
			}
		);

		if (res && res.statusCode === 200) {
			const uploadResponse = { ...res };
			setSessionDetails(prev => ({
				...prev,
				nodes: uploadResponse,
			}));
			resetAll();
			return true;
		} else {
			setIsShowSkip(true);
			errorNotification(res.message ?? 'Something went wrong');
			return false;
		}
	};

	const startAccreditation506c = async () => {
		const payload = {
			...commonPayload,
			start: true,
			actions: [
				{
					id: 'accreditationVerify',
					data: {
						accreditationType: '506c',
					},
				},
			],
		};
		// APIS.KYC_SESSION patch api
		const res = await postTokenSession({ payload, code: sessionCode });
		if (res && res.statusCode === 200) return;
		else {
			errorNotification(res.message ?? 'Something went wrong');
		}
	};

	const getSignEnvelopeStatusInterval = useCallback(
		async (
			envelopeId: string,
			interval: any,
			successCb: () => void,
			errorCb: () => void,
			recipientId: string
		): Promise<void> => {
			const resp = await get(
				`${APIS.ENVELOPE_STATUS}/${envelopeId}?recipientId=${recipientId}&getStatus=${true}`
			);
			const { data, statusCode } = resp;
			const { recipientStatus } = data ?? {};
			if (
				recipientStatus === 'underReview' ||
				recipientStatus === 'completed'
			) {
				clearInterval(interval);
				successCb();
			}
			if (statusCode !== 200) {
				clearInterval(interval);
				errorCb();
			}
		},
		[get]
	);

	return {
		submitFindraAccreditation,
		requestURL,
		submitAccred,
		submitUploadDocuments,
		startAccreditation506c,
		getSignEnvelopeStatusInterval,
		resetAll,
	};
};

export const useAccreditationSignatoryRequest = () => {
	// global state
	const accreditedByVerifier = useRecoilValue(AccreditedByVerifierState);
	const inputError = useRecoilValue(AccreditedByVerifierInputErrorState);
	const selectedkey = useRecoilValue(AccredSelectedKeyState);
	const { code: sessionCode } = useRecoilValue(AccessTokenState);
	const setIsShowSkip = useSetRecoilState(isShowSkipState);
	const [accredQueAndAns, setQuestionsAnswer] = useRecoilState(AccredQueAndAns);

	// hooks
	const {
		handleNext: goToNextStep,
		sessionPayloadDetail,
		setSessionDetails,
	} = useNextStep();
	const { onboardingType } = useSharedVariables();
	const { postTokenSession } = useTokenSession();
	const { errorNotification } = useNotification();
	const { resetAll } = useAccreditationRequests();

	const {
		_id: pipelineId,
		currentAction,
		stepsId,
		userId,
		organization,
	} = useMemo(() => sessionPayloadDetail ?? {}, [sessionPayloadDetail]);

	const questionsAnswer = useMemo(
		() =>
			accredQueAndAns.filter((item: { isDeleted: boolean }) => !item.isDeleted),
		[accredQueAndAns]
	);

	const commonPayload = {
		pipelineId,
		stepId: stepsId,
		userId,
	};

	const { accreditationDocument, accreditationType } =
		currentAction?.metadata ?? {};

	const type = (sessionPayloadDetail as any).currentAction.metadata
		?.accreditationType?.value;

	const submitSignatoryAccreditation = async (label: string, title: string) => {
		const finalQuestionnaire = JSON.parse(JSON.stringify(questionsAnswer));
		const index = finalQuestionnaire.findIndex(
			(item: { key: string }) => item.key === selectedkey
		);
		if (index !== -1) {
			finalQuestionnaire.splice(index, 1, {
				key: selectedkey,
				answers: label ?? '',
				questions: title ?? '',
			});
		} else {
			finalQuestionnaire.push({
				key: selectedkey,
				answers: label ?? '',
				questions: title ?? '',
			});
		}
		setQuestionsAnswer(finalQuestionnaire);
		const verifierData = accreditedByVerifier?.[selectedkey] ?? {};
		const payload: any = {
			...commonPayload,
			actions: [
				{
					id: currentAction?.key,
					data: {
						accreditationDocument,
						accreditationType,
						signatoryData: { ...verifierData, type: label },
						questionnaire: finalQuestionnaire,
					},
				},
			],
		};
		if (onboardingType === 'complex') {
			payload.nodeId = currentAction._id;
		}
		const res = await toast.promise(
			postTokenSession({ payload, code: sessionCode }),
			{
				pending: '....Sending request',
			}
		);

		if (res && res.statusCode === 200) {
			const uploadResponse = { ...res };
			setSessionDetails(prev => ({
				...prev,
				nodes: uploadResponse,
			}));
			resetAll();
			goToNextStep();
			return true;
		} else {
			setIsShowSkip(true);
			errorNotification(res.message ?? 'Something went wrong');
			return false;
		}
	};

	const nextDisabled = (selectedItems: {
		key: string;
		customComponent: JSX.Element;
	}) => {
		if (!selectedItems) return false;
		const { key: customKey = '', customComponent } = selectedItems ?? {};
		const verifierData = accreditedByVerifier?.[customKey];
		const { firstName, lastName, email } = verifierData ?? {};

		const errorData = inputError?.[customKey];
		const {
			firstName: firstNameError,
			lastName: lastNameError,
			email: emailError,
		} = errorData ?? {};

		if (customComponent) {
			return (
				!firstName?.trim() ||
				!lastName?.trim() ||
				!email?.trim() ||
				!!firstNameError ||
				!!lastNameError ||
				!!emailError
			);
		}
		return false;
	};

	const isSignatoryType = useMemo(
		() => selectedkey.endsWith('SignatoryType'),
		[selectedkey]
	);

	const findIndexRestrictedForNonOfAbow = useMemo(
		() =>
			restrictedForNonOfAbow.findIndex(
				({ name }) => name === organization?.name?.toLowerCase()
			),
		[organization?.name]
	);

	const isNonOfAbowNotAllowed = useCallback(
		(key: string) =>
			type === accreditationTypes['506c'] &&
			findIndexRestrictedForNonOfAbow !== -1 &&
			restrictedKeys[key ?? ''],
		[findIndexRestrictedForNonOfAbow, type]
	);

	const labelNext = useMemo(
		() => (isSignatoryType ? 'Submit' : 'Next'),
		[isSignatoryType]
	);

	const organizationName = useMemo(
		() => organization?.name ?? '',
		[organization?.name]
	);

	const handleInvestmentNotAllowedOnClose = useCallback(() => {
		if (findIndexRestrictedForNonOfAbow !== -1) {
			window.open(
				restrictedForNonOfAbow[findIndexRestrictedForNonOfAbow]?.url ?? '',
				'_blank'
			);
		}
	}, [findIndexRestrictedForNonOfAbow]);
	return {
		submitSignatoryAccreditation,
		nextDisabled,
		isSignatoryType,
		labelNext,
		isNonOfAbowNotAllowed,
		organizationName,
		handleInvestmentNotAllowedOnClose,
	};
};
