import { isMobileDevice } from 'utils';
import { distance2D } from './geometryUtils';
export function drawPlaceholder(style: any, canvas: HTMLCanvasElement): void {
	const canvasCtx = canvas?.getContext('2d');

	if (!canvasCtx || !canvas) {
		return;
	}
	const { lineColor } = style;

	const centerX = canvas.width / 2;
	const centerY = canvas.height / 2;
	const radius = distance2D(centerX, 0, centerX, centerY) / 4;

	//Set the fill color
	canvasCtx.fillStyle =
		lineColor === '#389F75'
			? 'rgba(56, 159, 117, 0.5)'
			: 'rgba(255, 255, 255, 0.5)';
	//Draw filled circle for the palm
	canvasCtx.beginPath();
	canvasCtx.arc(centerX, centerY, radius, 0, 2 * Math.PI, false);
	canvasCtx.fill();

	// Set border color and width
	canvasCtx.strokeStyle =
		lineColor === '#389F75'
			? 'rgba(56, 159, 117, 1)'
			: 'rgba(255, 255, 255, 1)'; // Solid white border
	canvasCtx.lineWidth = isMobileDevice() ? 15 : 5; // Adjust the width as needed
	canvasCtx.stroke();
}

function calculateEntropy(data: Uint8ClampedArray) {
	const histogram = new Array(256).fill(0);
	const totalPixels = data.length / 4;

	for (let i = 0; i < data.length; i += 4) {
		const r = data[i];
		const g = data[i + 1];
		const b = data[i + 2];
		// Convert RGB to grayscale using luminance formula
		if (r !== undefined && g !== undefined && b !== undefined) {
			const gray = 0.299 * r + 0.587 * g + 0.114 * b;
			histogram[Math.floor(gray)]++;
		}
	}

	let entropy = 0;
	for (let i = 0; i < 256; i++) {
		if (histogram[i] > 0) {
			const p = histogram[i] / totalPixels;
			entropy -= p * Math.log2(p);
		}
	}
	return entropy;
}

export function checkIsImageBlank(data: Uint8ClampedArray) {
	const entropy = calculateEntropy(data);
	const entropyThreshold = 5; // Adjust threshold as needed
	return entropy < entropyThreshold;
}
