import { useMemo } from 'react';

const useCheckParamInURL = (paramName: string): boolean => {
	const isParamPresent = useMemo(() => {
		try {
			// Get the current URL
			const url = window.location.href;

			// Create a URL object
			const urlObj = new URL(url);

			// Get the search parameters from the URL
			const params = new URLSearchParams(urlObj.search);

			// Check if the parameter is present
			return params.has(paramName);
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error('Invalid URL:', error);
			return false;
		}
	}, [paramName]);

	return isParamPresent;
};

export default useCheckParamInURL;
