export const PalmStepVerifiactionMessage = {
	HEADING: 'Palm video enrollment',
	SUBHEADING:
		'Follow the below instructions to ensure successful enrollment of your palm video.',
	STEPONE: 'Find a well-lit area for accurate detection.',
	STEPTWO:
		'Remove any accessories such as rings, gloves, etc., from your palms.',
	STEPTHREE:
		'Point the camera towards your open palm and position your hand within the rectangular box.',
	STEPFOUR: 'Hold still while we capture the video.',
};

export const Palm_Image =
	'https://storage.googleapis.com/satschel-assets-public/images/simplici-media/Frame_1.svg';

export const Palm_Camera_Image =
	'https://storage.googleapis.com/satschel-public-assets/images/kyc-frontend-media/Subtract.png';

export const PalmHandTypes = [
	{
		type: 'Left',
		imageUrl:
			'https://storage.googleapis.com/satschel-public-assets/images/kyc-frontend-media/left-palm.svg',
		Label: 'Left',
	},
	{
		type: 'Right',
		imageUrl:
			'https://storage.googleapis.com/satschel-public-assets/images/kyc-frontend-media/right-palm.svg',
		Label: 'Right',
	},
];
