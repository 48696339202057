const host = () => {
	return window.location.origin + '/';
};
export const envUrl = host();

export const __siteMetaData: any = {
	stage: {
		endPoint: 'https://api.stage.satschel.com/v2',
		appUrl: 'https://secure.stage.satschel.com',
		glareUrl: 'https://api.vouched.anandesh.dev/api/glare',
	},
	pp: {
		endPoint: 'https://api.pp.satschel.com/v2',
		appUrl: 'https://secure.pp.satschel.com',
		glareUrl: 'https://api.vouched.anandesh.dev/api/glare',
	},
	beta: {
		endPoint: 'https://api.beta.satschel.com/v2',
		appUrl: 'https://secure.beta.satschel.com',
		glareUrl: 'https://api.vouched.anandesh.dev/api/glare',
	},
	sandbox: {
		endPoint: 'https://api.beta.satschel.com/v2',
		appUrl: 'https://secure.beta.satschel.com',
		glareUrl: 'https://api.vouched.anandesh.dev/api/glare',
	},
	prod: {
		endPoint: 'https://api.satschel.com/v2',
		appUrl: 'https://secure.simplici.io',
		glareUrl: 'https://api.vouched.anandesh.dev/api/glare',
	},
};
