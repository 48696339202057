import {
	Button,
	CountryMobileNumber,
	DateCalender,
	Input,
	Loader,
	ReactSwitch,
} from '@storybook';
import {
	ChangeEvent,
	FC,
	Fragment,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { BodyWrapper, LabelElement } from 'components';
import { useNotification } from 'hooks';
import {
	IRepresentativeNameType,
	KycAmlVerificationToggleActionState,
	MultipleRepresentativeDetailsState,
	RepresentativeFilledToggleState,
} from 'views/kyb/stores';
import { useKYBRequests } from '../../stores/hooks';
import {
	NewRepresentative,
	REPRESENTATIVE_DETAILS_FORM,
	REPRESENTATIVE_MESSAGE,
	REPRESENTATIVE_VALIDATION_MESSAGE,
	RepresentativeFilledHeader,
} from './constant';

import {
	formatSlashedDate,
	getDateObj,
	isValidORDateGreaterThanToday,
	validateDOB,
	validateEmail,
	validateName,
} from 'utils';
import './representative-detail.scss';
import { KycAmlVerificationDetail } from '../kyc-aml-verification-toggle';
import classNames from 'classnames';

interface IRepresentativeDetail {
	handleSubmit: () => void;
	submitLoader: boolean;
	isKycAmlVerfication: boolean;
}

//Gaurav: Created multiple representative screen in kyb in complex case.
export const RepresentativeDetail: FC<IRepresentativeDetail> = ({
	handleSubmit,
	submitLoader,
	isKycAmlVerfication,
}) => {
	const [representativeNumber, setRepresentativeNumber] = useState<number>(1);
	const [isPhoneValid, setIsPhoneValid] = useState<boolean>(false);
	const [representativeDetails, setRepresentativeDetails] = useRecoilState(
		MultipleRepresentativeDetailsState
	);
	const isToggleOn = useRecoilValue(RepresentativeFilledToggleState);
	const [isKycAmlToggle, setIsKycAmlToggle] = useRecoilState(
		KycAmlVerificationToggleActionState
	);
	//Rahul Singh:Added state for error validation
	const [
		multipleRepresentativeDetailsErrorState,
		setMultipleRepresentativeDetailsErrorState,
	] = useState([
		{
			first_name: false,
			last_name: false,
			date_of_birth: false,
			national_id_number: false,
			telephone_number: false,
			email: false,
		},
	]);
	//Rahul Singh:Added state for error message
	const [isErrorMessage, setIsErrorMessage] = useState([
		{
			first_name: '',
			last_name: '',
			date_of_birth: '',
			national_id_number: '',
			telephone_number: '',
			email: '',
		},
	]);

	// hooks
	const { changeRepresentativeToggle, showPrefilledToggle, isInputDisabled } =
		useKYBRequests();
	const { renderExistingKycAmlToggle, renderDoKycAmlToggle } =
		KycAmlVerificationDetail();
	const { errorNotification } = useNotification();

	const represenativeRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (representativeDetails.length === 1) {
			setRepresentativeDetails((pre: any) => [
				{
					...pre[0],
				},
				...pre.slice(1), // Keep other objects unchanged
			]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		// only add email input when there is isKycAmlVerfication will true
		if (isKycAmlVerfication) {
			const updatedDetails = representativeDetails.map(detail => ({
				...detail,
				email: detail.email || '',
			}));
			setRepresentativeDetails(updatedDetails);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleValidation = (isValid: boolean) => {
		setIsPhoneValid(isValid);
	};
	const handleResetError = useCallback(() => {
		setMultipleRepresentativeDetailsErrorState(pre => {
			const prevValue = JSON.parse(JSON.stringify(pre));
			prevValue[0] = {
				first_name: false,
				last_name: false,
				date_of_birth: false,
				national_id_number: false,
				telephone_number: false,
			};
			return prevValue;
		});
		setIsErrorMessage(pre => {
			const prevValue = JSON.parse(JSON.stringify(pre));
			prevValue[0] = {
				first_name: '',
				last_name: '',
				date_of_birth: '',
				national_id_number: '',
				telephone_number: '',
			};
			return prevValue;
		});
	}, []);

	const handleAddRepresentative = useCallback(() => {
		if (representativeNumber === 5) {
			return errorNotification(REPRESENTATIVE_MESSAGE.WARNING_MAX);
		}
		represenativeRef.current?.scrollIntoView({
			behavior: 'smooth',
			block: 'end',
		});

		setRepresentativeDetails(pre => [
			...pre,
			{
				...NewRepresentative,
				...(isKycAmlVerfication && { email: '' }),
			},
		]);
		setRepresentativeNumber(pre => pre + 1);

		// adding id in kyc-aml verification toogle state
		setIsKycAmlToggle(prev => {
			const newObject = {
				isFreshKycLink: isKycAmlToggle?.[0]?.isFreshKycLink || false,
				doKyc: false,
				id: prev.length,
			};
			return [...prev, newObject];
		});

		//Rahul singh:manage error state on being add represenative
		setMultipleRepresentativeDetailsErrorState(pre => [
			...pre,
			{
				first_name: false,
				last_name: false,
				date_of_birth: false,
				national_id_number: false,
				telephone_number: false,
				email: false,
			},
		]);
		//Rahul singh:manage error message state on being add represenative
		setIsErrorMessage(pre => [
			...pre,
			{
				first_name: '',
				last_name: '',
				date_of_birth: '',
				national_id_number: '',
				telephone_number: '',
				email: '',
			},
		]);
		return null;
	}, [
		representativeNumber,
		setRepresentativeDetails,
		setIsKycAmlToggle,
		errorNotification,
		isKycAmlVerfication,
		isKycAmlToggle,
	]);

	//Gaurav: After using callback not able to edit input.
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const removeAtIndex = (index: number) => {
		setRepresentativeDetails(prevArray => {
			// Create a new array by excluding the element at the specified index
			const newArray = [
				...prevArray.slice(0, index),
				...prevArray.slice(index + 1),
			];
			return newArray;
		});
	};

	const handleToggleChange = useCallback(
		(id: number) => {
			setIsKycAmlToggle(prevToggles => {
				return prevToggles.map(toggle => {
					if (toggle.id === id) {
						return {
							...toggle,
							doKyc: !toggle.doKyc,
						};
					}
					return toggle;
				});
			});
		},
		[setIsKycAmlToggle]
	);

	const handleRemoveRepresentative = useCallback(
		(index: number) => {
			if (representativeNumber === 1) {
				return errorNotification(REPRESENTATIVE_MESSAGE.WARNING_MIN);
			}
			removeAtIndex(index);

			setRepresentativeNumber(pre => pre - 1);

			// remove object from kyc-aml toggle state
			setIsKycAmlToggle(prevToggles => {
				const filteredToggles = prevToggles.filter(
					toggle => toggle.id !== index
				);

				// Update the IDs of the remaining objects
				const updatedToggles = filteredToggles.map((toggle, index) => ({
					...toggle,
					id: index,
				}));

				return updatedToggles;
			});

			//Rahul singh:manage error state on being remove represenative
			setMultipleRepresentativeDetailsErrorState(prevArray => {
				const newArray = [...prevArray];
				newArray.splice(index, 1, {
					first_name: false,
					last_name: false,
					date_of_birth: false,
					national_id_number: false,
					telephone_number: false,
					email: false,
				});
				return newArray;
			});
			return null;
		},
		[representativeNumber, removeAtIndex, errorNotification, setIsKycAmlToggle]
	);

	const onHandlePhoneChange = useCallback(
		(countryCode: string | number, phone: string, repNo: number) => {
			//Rahul singh:manage error state on phone input change
			setMultipleRepresentativeDetailsErrorState(prev => {
				const newObj = [...prev];
				(newObj[repNo] as any).telephone_number = false;
				return newObj;
			});
			setIsErrorMessage((prev: any) => {
				const newMessages = [...prev];
				newMessages[repNo].telephone_number = '';
				return newMessages;
			});

			if ((/^\d+$/.test(phone) || phone === '') && phone.length <= 12) {
				const number = phone;
				setRepresentativeDetails((pre: any) =>
					Object.values({
						...pre,
						[repNo]: {
							...pre[repNo],
							telephone_number: number,
							country_code: countryCode,
						},
					})
				);
				if (phone.length < 8) {
					setIsErrorMessage((prev: any) => {
						const newMessages = [...prev];
						newMessages[repNo].telephone_number =
							'Phone should be at least 8 digits';
						return newMessages;
					});
					setMultipleRepresentativeDetailsErrorState(prev => {
						const newObj = [...prev];
						(newObj[repNo] as any).telephone_number = true;
						return newObj;
					});
				}
			}
		},

		[setRepresentativeDetails]
	);

	const onHandleChangeCountry = useCallback(
		(countryCode: string | number, repNo: number) => {
			setRepresentativeDetails((pre: any) =>
				Object.values({
					...pre,
					[repNo]: {
						...pre[repNo],
						country_code: countryCode,
					},
				})
			);
		},
		[setRepresentativeDetails]
	);

	const handleInputChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>, repNo: number, key?: string) => {
			const { name, value } = e?.target ?? {};

			if (key === 'date_of_birth' && isValidORDateGreaterThanToday(value)) {
				setMultipleRepresentativeDetailsErrorState(prev => {
					const multipleRepresentativeDetailsObj: any = [...prev];
					multipleRepresentativeDetailsObj[repNo].date_of_birth = true;
					return multipleRepresentativeDetailsObj;
				});
				setIsErrorMessage(prev => {
					const newMessages: any = [...prev];
					newMessages[repNo].date_of_birth = 'Invalid date';
					return newMessages;
				});
				return;
			}

			if (key === 'date_of_birth' && !validateDOB(value)) {
				setMultipleRepresentativeDetailsErrorState(prev => {
					const multipleRepresentativeDetailsObj: any = [...prev];
					multipleRepresentativeDetailsObj[repNo].date_of_birth = true;
					return multipleRepresentativeDetailsObj;
				});
				setIsErrorMessage(prev => {
					const newMessages: any = [...prev];
					newMessages[repNo].date_of_birth =
						REPRESENTATIVE_VALIDATION_MESSAGE.VALIDATE_DOB;
					return newMessages;
				});
				return;
			}

			//Rahul singh:manage error state on being input change
			setMultipleRepresentativeDetailsErrorState(prev => {
				const newObj = [...prev];
				(newObj[repNo] as any)[name] = false;
				return newObj;
			});
			setIsErrorMessage((prev: any) => {
				const newMessages = [...prev];
				newMessages[repNo][name] = '';
				return newMessages;
			});

			if (key === 'date_of_birth') {
				setMultipleRepresentativeDetailsErrorState(prev => {
					const multipleRepresentativeDetailsObj: any = [...prev];
					multipleRepresentativeDetailsObj[repNo].date_of_birth = false;
					return multipleRepresentativeDetailsObj;
				});
				setIsErrorMessage((prev: any) => {
					const newMessages = [...prev];
					newMessages[repNo].date_of_birth = '';
					return newMessages;
				});

				setRepresentativeDetails((pre: any) =>
					Object.values({
						...pre,
						[repNo]: { ...pre[repNo], [key]: value },
					})
				);
			} else if (name === 'national_id_number') {
				const number = value;
				const validSsnRegex = /^[0-9a-zA-Z/-]*( [0-9a-zA-Z/-]+)*$/;
				const digits = number?.replace(/[^0-9a-zA-Z]/g, '');
				const ssnValue = number
					.replace(/[^0-9a-zA-Z/\- ]/g, '')
					.replace(/\s+/g, ' ');
				if (!validSsnRegex.test(number.trim())) {
					setMultipleRepresentativeDetailsErrorState(prev => {
						const multipleRepresentativeDetailsObj: any = [...prev];
						multipleRepresentativeDetailsObj[repNo].national_id_number = true;
						return multipleRepresentativeDetailsObj;
					});
					setIsErrorMessage(prev => {
						const newMessages: any = [...prev];
						newMessages[repNo].national_id_number =
							REPRESENTATIVE_VALIDATION_MESSAGE.NATIONAL_ID_NUMBER_VALIDATION;
						return newMessages;
					});
				}
				if (digits.length > 15 && validSsnRegex.test(number.trim())) {
					setMultipleRepresentativeDetailsErrorState(prev => {
						const multipleRepresentativeDetailsObj: any = [...prev];
						multipleRepresentativeDetailsObj[repNo].national_id_number = true;
						return multipleRepresentativeDetailsObj;
					});
					setIsErrorMessage(prev => {
						const newMessages: any = [...prev];
						newMessages[repNo].national_id_number =
							REPRESENTATIVE_VALIDATION_MESSAGE.NATIONAL_ID_NUMBER_VALIDATION_MAX;
						return newMessages;
					});
				}
				if (digits.length < 9 && validSsnRegex.test(number.trim())) {
					setMultipleRepresentativeDetailsErrorState(prev => {
						const multipleRepresentativeDetailsObj: any = [...prev];
						multipleRepresentativeDetailsObj[repNo].national_id_number = true;
						return multipleRepresentativeDetailsObj;
					});
					setIsErrorMessage(prev => {
						const newMessages: any = [...prev];
						newMessages[repNo].national_id_number =
							REPRESENTATIVE_VALIDATION_MESSAGE.NATIONAL_ID_NUMBER_VALIDATION_MIN;
						return newMessages;
					});
				}
				if (
					digits.length > 9 &&
					digits.length <= 15 &&
					validSsnRegex.test(number.trim())
				) {
					setMultipleRepresentativeDetailsErrorState(prev => {
						const multipleRepresentativeDetailsObj: any = [...prev];
						multipleRepresentativeDetailsObj[repNo].national_id_number = false;
						return multipleRepresentativeDetailsObj;
					});
					setIsErrorMessage(prev => {
						const newMessages: any = [...prev];
						newMessages[repNo].national_id_number = '';
						return newMessages;
					});
				}

				if (digits.length <= 15 && validSsnRegex.test(number.trim())) {
					const isOnlyDigitsRegex = /^[a-zA-Z0-9]+$/;
					const valueToSet = isOnlyDigitsRegex.test(number.trim())
						? number.replace(/(\w{3})(\w{2})(\w{4})/, '$1-$2-$3')
						: ssnValue;
					setRepresentativeDetails((pre: any) =>
						Object.values({
							...pre,
							[repNo]: {
								...pre[repNo],
								[name]: valueToSet,
							},
						})
					);
				}
				return;
			} else if (name === 'zip') {
				let number = value;
				number = number?.replace(/[^0-9a-zA-Z]/g, '');
				number = number?.substring(0, 10);
				setRepresentativeDetails((pre: any) =>
					Object.values({
						...pre,
						[repNo]: {
							...pre[repNo],
							[name]: number,
						},
					})
				);
			} else {
				setRepresentativeDetails((pre: any) =>
					Object.values({
						...pre,
						[repNo]: { ...pre[repNo], [name]: value },
					})
				);
			}
		},
		[setRepresentativeDetails, setMultipleRepresentativeDetailsErrorState]
	);

	const getValueInput = useCallback(
		(key: IRepresentativeNameType, repNo: number) => {
			if (key === 'national_id_number') {
				return representativeDetails[repNo][key].length <= 9
					? representativeDetails[repNo][key]?.replace(
							/(\w{3})(\w{2})(\w{4})/,
							'$1-$2-$3'
						)
					: representativeDetails[repNo][key];
			} else {
				return representativeDetails[repNo][key];
			}
		},
		[representativeDetails]
	);

	const handleTextInputBlur = useCallback(
		(repNo: number, key: string) => {
			if (
				(key === 'first_name' || key === 'last_name') &&
				!validateName(representativeDetails[repNo][key].trim())
			) {
				setIsErrorMessage((prev: any) => {
					const newMessages = [...prev];
					newMessages[repNo][key] =
						`Please enter a valid ${key === 'first_name' ? 'name.' : 'last name.'}`;
					return newMessages;
				});

				setMultipleRepresentativeDetailsErrorState(prev => {
					const multipleRepresentativeDetailsObj: any = [...prev];
					multipleRepresentativeDetailsObj[repNo][key] = true;
					return multipleRepresentativeDetailsObj;
				});
			}

			setRepresentativeDetails(prev => {
				// eslint-disable-next-line prefer-const
				let data = structuredClone(prev);
				data[repNo][key] = data[repNo][key]?.trim();
				return data;
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[representativeDetails]
	);

	const getInput = useCallback(
		(repNo: number) => {
			return REPRESENTATIVE_DETAILS_FORM?.map((el, index: number) => {
				// @paras: Only in case of isKycAmlVerfication is true then return 'email' input 	else return null
				if (!isKycAmlVerfication && el.name === 'email') {
					return null;
				}
				switch (el.type) {
					case 'text':
						return (
							<Fragment key={`${el.name}_${index.toString()}`}>
								<Input
									isRequired={true}
									label={el.label}
									inputType={el.type}
									placeholder={el.placeHolder}
									handleChange={e => handleInputChange(e, repNo)}
									handleBlur={() => handleTextInputBlur(repNo, el.name)}
									inputName={el.name}
									isError={
										(multipleRepresentativeDetailsErrorState[repNo] as any)[
											el.name
										]
									}
									errorMessage={(isErrorMessage[repNo] as any)[el.name]}
									value={getValueInput(
										el.name as IRepresentativeNameType,
										repNo
									)}
									disabled={isInputDisabled(el.name, repNo)}
								/>
							</Fragment>
						);

					case 'phone':
						return (
							<CountryMobileNumber
								label={el.label}
								handleChange={e => {
									onHandlePhoneChange(e.countryCode, e.phone, repNo);
								}}
								defaultCountryCode={
									representativeDetails[repNo]['country_code'] || '+1'
								}
								defaultNumber={representativeDetails[repNo]['telephone_number']}
								handleChangeCountry={e => onHandleChangeCountry(e, repNo)}
								isRequired
								errorMessage={(isErrorMessage[repNo] as any)[el.name]}
								isError={
									(multipleRepresentativeDetailsErrorState[repNo] as any)[
										el.name
									]
								}
								disabled={isInputDisabled(el.name, repNo)}
								handleValidation={handleValidation}
							/>
						);

					case 'date':
						return (
							<DateCalender
								value={getDateObj(
									representativeDetails[repNo][el.name as 'date_of_birth']
								)}
								defaultValue={formatSlashedDate(
									representativeDetails[repNo][el.name as 'date_of_birth']
								)}
								label={el.label}
								onChange={(e: any) => handleInputChange(e, repNo, el.name)}
								isRequired
								errorMessage={(isErrorMessage[repNo] as any)[el.name]}
								disabled={isInputDisabled(el.name, repNo)}
								isError={
									(multipleRepresentativeDetailsErrorState[repNo] as any)[
										el.name
									]
								}
							/>
						);
					default:
						return <></>;
				}
			});
		},
		[
			multipleRepresentativeDetailsErrorState,
			isErrorMessage,
			getValueInput,
			isInputDisabled,
			representativeDetails,
			handleTextInputBlur,
			handleInputChange,
			onHandlePhoneChange,
			isKycAmlVerfication,
			onHandleChangeCountry,
		]
	);

	//Rahul Singh: function to check empty input
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const validateInputLength = (key: string, repNo: number) => {
		let isEmpty = false;
		if (key === 'date_of_birth') {
			const date = (representativeDetails[repNo] as any)[key];
			if (date) {
				isEmpty = Object.values(date ?? {}).some(value => !value);
				return;
			}
			isEmpty = date?.trim() === '';
		} else if (key === 'email') {
			const emailValue = (representativeDetails[repNo] as any)[key];
			isEmpty = emailValue?.trim() === '';

			return isEmpty;
		} else {
			isEmpty = (representativeDetails[repNo] as any)[key].trim() === '';
		}
		return isEmpty;
	};

	const isKybRemoveSubmitClass = useMemo(() => {
		return classNames('representative-add_delete', {
			'representative-add__disabled': submitLoader,
		});
	}, [submitLoader]);
	//Rahul singh:Function to handle validation on onSubmit
	const handleSubmitRepresentativeDetails = useCallback(() => {
		const validSsnRegex = /^[0-9a-zA-Z/-]*( [0-9a-zA-Z/-]+)*$/;
		let hasErrors = false;
		for (let repNo = 0; repNo < representativeNumber; repNo++) {
			if (validateInputLength('first_name', repNo)) {
				setMultipleRepresentativeDetailsErrorState(prev => {
					const multipleRepresentativeDetailsObj: any = [...prev];
					multipleRepresentativeDetailsObj[repNo].first_name = true;
					return multipleRepresentativeDetailsObj;
				});
				hasErrors = true;
			}
			setIsErrorMessage(prev => {
				const newMessages: any[] = [...prev];
				newMessages[repNo].first_name =
					REPRESENTATIVE_VALIDATION_MESSAGE.FIRST_NAME_VALIDATION;
				return newMessages;
			});

			if (validateInputLength('last_name', repNo)) {
				setMultipleRepresentativeDetailsErrorState(prev => {
					const multipleRepresentativeDetailsObj: any = [...prev];
					multipleRepresentativeDetailsObj[repNo].last_name = true;
					return multipleRepresentativeDetailsObj;
				});
				hasErrors = true;
			}
			setIsErrorMessage(prev => {
				const newMessages: any[] = [...prev];
				newMessages[repNo].last_name =
					REPRESENTATIVE_VALIDATION_MESSAGE.LAST_NAME_VALIDATION;
				return newMessages;
			});

			if (
				representativeDetails[repNo]?.telephone_number.replace(
					representativeDetails[repNo].country_code,
					''
				).length < 8
			) {
				setMultipleRepresentativeDetailsErrorState(prev => {
					const multipleRepresentativeDetailsObj: any[] = [...prev];
					multipleRepresentativeDetailsObj[repNo].telephone_number = true;
					return multipleRepresentativeDetailsObj;
				});
				hasErrors = true;
			}
			setIsErrorMessage(prev => {
				const newMessages: any[] = [...prev];
				newMessages[repNo].telephone_number =
					REPRESENTATIVE_VALIDATION_MESSAGE.TELEPHONE_NUMBER_VALIDATION;
				return newMessages;
			});

			if (
				representativeDetails[repNo]?.national_id_number?.replace(
					/[^0-9a-zA-Z]/g,
					''
				).length < 9 &&
				validSsnRegex.test(representativeDetails[repNo]?.national_id_number)
			) {
				setMultipleRepresentativeDetailsErrorState(prev => {
					const multipleRepresentativeDetailsObj: any[] = [...prev];
					multipleRepresentativeDetailsObj[repNo].national_id_number = true;
					return multipleRepresentativeDetailsObj;
				});
				hasErrors = true;
				setIsErrorMessage(prev => {
					const newMessages: any[] = [...prev];
					newMessages[repNo].national_id_number =
						REPRESENTATIVE_VALIDATION_MESSAGE.NATIONAL_ID_NUMBER_VALIDATION_MIN;
					return newMessages;
				});
			}
			if (
				representativeDetails[repNo]?.national_id_number?.replace(
					/[^0-9a-zA-Z]/g,
					''
				).length > 15 &&
				validSsnRegex.test(representativeDetails[repNo]?.national_id_number)
			) {
				setMultipleRepresentativeDetailsErrorState(prev => {
					const multipleRepresentativeDetailsObj: any[] = [...prev];
					multipleRepresentativeDetailsObj[repNo].national_id_number = true;
					return multipleRepresentativeDetailsObj;
				});
				hasErrors = true;
				setIsErrorMessage(prev => {
					const newMessages: any[] = [...prev];
					newMessages[repNo].national_id_number =
						REPRESENTATIVE_VALIDATION_MESSAGE.NATIONAL_ID_NUMBER_VALIDATION_MAX;
					return newMessages;
				});
			}

			if (validateInputLength('date_of_birth', repNo)) {
				setMultipleRepresentativeDetailsErrorState(prev => {
					const multipleRepresentativeDetailsObj: any[] = [...prev];
					multipleRepresentativeDetailsObj[repNo].date_of_birth = true;
					return multipleRepresentativeDetailsObj;
				});
				hasErrors = true;
			}
			setIsErrorMessage(prev => {
				const newMessages: any[] = [...prev];
				newMessages[repNo].date_of_birth =
					REPRESENTATIVE_VALIDATION_MESSAGE.VALIDATE_DATE;

				return newMessages;
			});

			// eslint-disable-next-line no-prototype-builtins
			if (representativeDetails[repNo]?.hasOwnProperty('email')) {
				if (validateInputLength('email', repNo)) {
					setMultipleRepresentativeDetailsErrorState(prev => {
						const multipleRepresentativeDetailsObj: any[] = [...prev];
						multipleRepresentativeDetailsObj[repNo].email = true;
						return multipleRepresentativeDetailsObj;
					});

					hasErrors = true;
					setIsErrorMessage(prev => {
						const newMessages: any[] = [...prev];
						newMessages[repNo].email =
							REPRESENTATIVE_VALIDATION_MESSAGE.EMAIL_VALIDATION;
						return newMessages;
					});
					return;
				}
				if (validateEmail((representativeDetails[repNo] as any)['email'])) {
					setMultipleRepresentativeDetailsErrorState(prev => {
						const multipleRepresentativeDetailsObj: any[] = [...prev];
						multipleRepresentativeDetailsObj[repNo].email = true;
						return multipleRepresentativeDetailsObj;
					});
					hasErrors = true;
					setIsErrorMessage(prev => {
						const newMessages: any[] = [...prev];
						newMessages[repNo].email =
							REPRESENTATIVE_VALIDATION_MESSAGE.EMAIL_NOT_VALID;
						return newMessages;
					});
					return;
				}
			}
		}

		if (!hasErrors) {
			handleSubmit();
		}
	}, [
		handleSubmit,
		representativeDetails,
		representativeNumber,
		validateInputLength,
	]);

	const renderFilledToggle = useMemo(() => {
		return (
			showPrefilledToggle() && (
				<div className="Representative-detail--filledWrapper">
					<div className="Representative-detail--filledHeader">
						<div className="Representative-detail--filledTitle">
							{RepresentativeFilledHeader.title}
						</div>
						<div className="Representative-detail--filledDesc">
							{RepresentativeFilledHeader.desc}
						</div>
					</div>
					<div className="Representative-detail--filledToggle">
						<ReactSwitch
							checked={isToggleOn}
							handleChange={(value: boolean) =>
								changeRepresentativeToggle(value, handleResetError)
							}
							id={'representative-filled-toggle'}
						/>
					</div>
				</div>
			)
		);
	}, [
		changeRepresentativeToggle,
		handleResetError,
		isToggleOn,
		showPrefilledToggle,
	]);

	const representativeBody = useMemo(() => {
		return (
			<>
				<div className="Representative-detail">
					<div className="representative-note">
						<i className="ri-information-line representative-note_icon" />
						<div className="representative-note_text">
							<span> Note:</span> You can add up to 5 company representatives.
						</div>
					</div>
					<div className="representative-wrapper">
						{isKycAmlVerfication && renderExistingKycAmlToggle}
						<div className="representative-add">
							<div>Representatives</div>
							<div
								className="representative-add_button"
								onClick={handleAddRepresentative}
							>
								Add Representative
							</div>
						</div>
						<div className="representative-wrapper">
							{Array(representativeNumber)
								.fill({})
								.map((_item, index) => {
									const isToggle = isKycAmlToggle.find(
										toggle => toggle.id === index
									);
									return (
										<div
											className="representative-add-wrapper"
											key={`representative_${index.toString()}`}
											ref={represenativeRef}
										>
											<div className="representative-add">
												<div className="representative-add_text">
													Representative
												</div>
												{index !== 0 && (
													<button
														disabled={submitLoader}
														className={isKybRemoveSubmitClass}
														onClick={() => handleRemoveRepresentative(index)}
													>
														Remove
													</button>
												)}
											</div>
											{isKycAmlVerfication &&
												renderDoKycAmlToggle(
													() => handleToggleChange(index),
													isToggle
												)}
											{index === 0 && renderFilledToggle}
											{getInput(index)}
										</div>
									);
								})}
						</div>
					</div>
				</div>
				<div className="representative-wrapper--representative-btn">
					<Button
						label={
							submitLoader ? (
								<>
									Submitting...
									<div>
										<Loader type="circle" dimension={26} />
									</div>
								</>
							) : (
								'Submit'
							)
						}
						handleClick={handleSubmitRepresentativeDetails}
						disabled={!isPhoneValid || submitLoader}
						type="button__filled button__filled--primary button__large button__block mt-2 process-button__size"
					/>
				</div>
			</>
		);
	}, [
		isKycAmlVerfication,
		renderExistingKycAmlToggle,
		handleAddRepresentative,
		representativeNumber,
		submitLoader,
		handleSubmitRepresentativeDetails,
		isKycAmlToggle,
		isKybRemoveSubmitClass,
		renderDoKycAmlToggle,
		renderFilledToggle,
		getInput,
		handleRemoveRepresentative,
		handleToggleChange,
		isPhoneValid,
	]);

	return (
		<BodyWrapper
			label={<LabelElement text="Representative Verification" />}
			headerElement={
				<div className="kyb-details__header representative-header">
					<div className="kyb-details__header__title">
						Representative Details
					</div>
					<div className="kyb-details__header__sub-title">
						Please provide all these details about the representative.
					</div>
				</div>
			}
			optionalClassNameBody="ppl-screen-body__overflow"
			bodyElement={representativeBody}
		/>
	);
};
