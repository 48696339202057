import { atom } from 'recoil';

type IceServer = {
	urls?: string[];
	username?: string;
	credential?: string;
};
export type IceServers = IceServer[];

export const PeerConnectionState = atom<RTCPeerConnection | null>({
	key: 'peer-connection-state',
	default: null,
});

export const CameraStreamingState = atom<MediaStream | null>({
	key: 'camera-connection-state',
	default: null,
});

export const IsWebRtcConnectionEnabledState = atom<boolean>({
	key: 'is-enabled-web-rtc-key-state',
	default: false,
});

export const IsEnrollIceServerListCalledState = atom<boolean>({
	key: 'is-ice-server-list-called-key',
	default: false,
});

export const EnrollIceServerListState = atom<IceServers>({
	key: 'ice-server-list-key',
	default: [],
});
