export const ExtraSteps = [
	{
		_id: 'term-condition',
		label: 'Term Condition',
		stepId: 'term-condition',
		actions: [
			{
				iframe: false,
				_id: '63c96c1a3115ca486bfbb721',
				label: 'Term Condition',
				key: 'term-condition',
				value: [],
				status: 'pending',
			},
		],
	},
];

export const ExtraStepsQR = [
	{
		_id: 'term-condition',
		label: 'Term Condition',
		stepId: 'term-condition',
		actions: [
			{
				iframe: false,
				_id: '63c96c1a3115ca486bfbb721',
				label: 'Term Condition',
				key: 'term-condition',
				value: [],
				status: 'pending',
			},
		],
	},
	{
		_id: 'basic-information',
		label: 'Basic Information',
		stepId: 'basic-information',
		actions: [
			{
				iframe: false,
				_id: '63c96c1a3115ca486bfbb723',
				label: 'Basic Information',
				key: 'basicInformation',
				value: [],
				status: 'pending',
			},
		],
	},
];

export const ExtraStepsLinearQR = [
	{
		_id: 'term-condition',
		label: 'Term Condition',
		stepId: 'term-condition',
		actions: [
			{
				iframe: false,
				_id: '63c96c1a3115ca486bfbb721',
				label: 'Term Condition',
				key: 'term-condition',
				value: [],
				status: 'pending',
			},
		],
	},
	{
		_id: 'basic-information',
		label: 'Basic Information',
		stepId: 'basic-information',
		actions: [
			{
				iframe: false,
				_id: '63c96c1a3115ca486bfbb723',
				label: 'Basic Information',
				key: 'basicInformation',
				value: [],
				status: 'pending',
			},
		],
	},
	{
		_id: 'facialRecognition',
		label: 'Facial Recognition',
		stepId: 'facialRecognition',
		actions: [
			{
				iframe: false,
				_id: '',
				label: 'Facial Recognition',
				key: 'facialRecognition',
				value: [],
				status: 'pending',
			},
		],
	},
];

export const ExtraStepsDoLater = [
	{
		_id: 'kyb-form',
		label: 'KYB Form',
		stepId: 'kybForm',
		actions: [
			{
				iframe: false,
				_id: '63c96c1a3115ca486bfbb720',
				label: 'KYB Form',
				key: 'kybForm',
				value: [],
				status: 'pending',
			},
		],
	},
];

export const dolaterSuccess = {
	stepId: 'successScreen',
	actions: [
		{
			metadata: {
				successScreenDocTitle: 'Yay!',
				successScreenMessage: 'Verification Complete',
			},
			key: 'successScreenCompletion',
			iframe: false,
			label: 'Success Screen',
			status: 'pending',
		},
	],
	label: 'Success Screen',
	requestUrl: null,
};

export const headers = [
	'First Name',
	'Last Name',
	'Email',
	'Mobile',
	'Country Code',
	'Dollars Invested',
];

export const BY_PSSS_URL = [
	'http://localhost:3000/',
	'http://localhost:3001/',
	'http://localhost:3002/',
	'https://admin.stage.satschel.com/',
	'https://admin.pp.satschel.com/',
	'https://app.simplici.io/',
];

export const CUSTOM_ORDER_STEPS = ['term-condition', 'authentication'];
export const BYPASS_STEPS = ['term-condition', 'authentication'];
export const PREVENT_SKIP_FOR_STEPS = [
	'term-condition',
	'facialRecognition',
	'successScreenCompletion',
];

export const PREVENT_BREADCRUMB_FOR_STEPS = [
	'term-condition',
	'facialRecognition',
	'basicInformation',
];

export const PREVENT_SKIP_AND_LOCATEME_URLS = [
	'http://localhost:3000/',
	'https://admin.stage.satschel.com/',
	'https://admin.pp.satschel.com/',
	'https://exchange.stage.satschel.com/',
	'https://exchange.pp.satschel.com/',
	'https://exchange.stealthmode.me/',
	'https://app.liquidity.io/',
];

export const PREVENT_WEBAUTHN_FOR_URLS = [
	'http://localhost:3000/',
	'https://exchange.stage.satschel.com/',
	'https://exchange.pp.satschel.com/',
	'https://exchange.stealthmode.me/',
	'https://app.liquidity.io/',
	'https://liquidity.io/',
	'https://admin.stage.satschel.com/',
	'https://admin.pp.satschel.com/',
	'https://app.simplici.io/',
];

export const QR_CONTINUE_LABEL = {
	new: 'New Session',
	old: 'Old session',
	new_description: 'Fresh start with all onboarding steps',
	old_description: 'Pick up where you left off',
	title:
		'Your session is already created, do you want to continue with old data or create a new one?',
	header: 'Choose what you want to do',
	subHeader:
		'A session already exists with same user information for this onboarding.',
};

export const ExtraComplexSteps = [
	{
		_id: 'term-condition',
		label: 'Term Condition',
		stepId: 'term-condition',
		actions: [
			{
				iframe: false,
				_id: '63c96c1a3115ca486bfbb721',
				label: 'Term Condition',
				key: 'term-condition',
				value: [],
				status: 'pending',
			},
		],
	},
	{
		_id: 'facialRecognition',
		label: 'Facial Recognition',
		stepId: 'facialRecognition',
		actions: [
			{
				iframe: false,
				_id: '',
				label: 'Facial Recognition',
				key: 'facialRecognition',
				value: [],
				status: 'pending',
			},
		],
	},
];

export const IGNORE_METADATA_KEYS = ['type', 'session'];
