import { useEffect } from 'react';
import './palm-enroll.scss';
import { usePalm } from 'views/palm-enroll/hooks/use-palm';
import {
	IsPalmDetected,
	IsPalmLoadedState,
	PalmEnrollCountdown,
	// palmHandErrorCheck,
	// PalmHandType,
} from 'views/palm-enroll/states/palm-screen-state';
import { useRecoilValue } from 'recoil';
import { PalmSetupModal } from './palm-setupModel';
// import { useNotification } from 'hooks';
// import { Palm_Camera_Image } from '../palm-information/constant/information';
// import { isMobileDevice } from 'utils';

export const PalmEnroll = () => {
	const { start } = usePalm();
	const isPalmMeshModelLoaded = useRecoilValue(IsPalmLoadedState);
	const IsPalmDetectedLoaded = useRecoilValue(IsPalmDetected);
	const palmCountDown = useRecoilValue(PalmEnrollCountdown);
	// const palmHand = useRecoilValue(PalmHandType);
	// const [isMobile, setIsMobile] = useState(false);
	// const PalmHandErrorCheck = useRecoilValue(palmHandErrorCheck);
	// const { errorNotification } = useNotification();

	// eslint-disable-next-line no-console
	// console.log("palmHand------->",palmHand);

	const onResults = () => {};
	useEffect(() => {
		const video = document.getElementById('palmEnroll') as HTMLVideoElement;
		const canvasElement = document.getElementById(
			'palm_canvas'
		) as HTMLCanvasElement;

		start({
			video,
			canvas: canvasElement,
			onResults,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// useEffect(() => {
	// 	if(!PalmHandErrorCheck){
	// 		errorNotification("Wrong Palm Detected")
	// 	}

	// // eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [PalmHandErrorCheck]);

	return (
		<div className="palm-wrapper-container">
			{!isPalmMeshModelLoaded ? (
				<PalmSetupModal />
			) : (
				<div>
					{palmCountDown < 0 ? (
						<div
							className={`palm-info-instructions ${palmCountDown > 0 ? 'palm-info-instructions--countDown' : ''}`}
						>
							{IsPalmDetectedLoaded.includes('position-within-circle') && (
								<p className="palm-info-text__facial-enroll ">
									{`Move your palm closer to camera and`}
									<br />
									{`try to centre blue circle
									with the white circle`}
								</p>
							)}
							{IsPalmDetectedLoaded.includes('position-rectangular') && (
								<p className="palm-info-text__facial-enroll ">
									Position your palm within the rectangular box
								</p>
							)}
							{IsPalmDetectedLoaded.includes('wrong-palm') && (
								<p className="palm-info-text__facial-enroll ">
									Wrong Palm detected or no palm detected
								</p>
							)}
						</div>
					) : (
						<>
							<div className={`palm-info-instructions`}>
								<p className="palm-info-text__facial-enroll ">
									{`Hold your hand still! We're taking a picture`} <br />
									{`of your palm. Get ready!`}
								</p>
							</div>
							<p className="palm-info-text__countDown">{palmCountDown}</p>
						</>
					)}

					{/* <div className="palm-overlayDiv">
						{!isMobile ? (
							<img
								className={`palm-overlay-image ${palmHand === 'Right' ? 'rotate180' : ''}`}
								src={Palm_Camera_Image}
							/>
						) : (
							<img
								className={`palm-overlay-image ${palmHand === 'Left' ? 'rotate180' : ''}`}
								src={Palm_Camera_Image}
							/>
						)}
					</div> */}
				</div>
			)}
			<video
				id="palmEnroll"
				className={`palm-video-camera  ${!isPalmMeshModelLoaded ? 'hide-video-webcam__PalmEnroll' : ''}`}
				autoPlay
				playsInline
			></video>
			<canvas className="palm_canvas" id="palm_canvas"></canvas>
		</div>
	);
};
