import { ChangeEvent, FC, useCallback, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Button, Input } from '@storybook';

import { convertToCurrencySystem } from 'utils';
import { useNextStep } from 'hooks';
import { PayInUnitPricingState } from 'views/fund-investment/stores';
import {
	MAX_DEFAULT_AMOUNT,
	MIN_DEFAULT_AMOUNT,
} from 'views/fund-investment/constants';
import { FundNameState } from 'hooks/use-next-step/stores';

import './unit-price.scss';

interface IUnitPrice {
	handleConnect: () => void;
}

export const UnitPrice: FC<IUnitPrice> = ({ handleConnect }) => {
	// Recoil state for managing unit pricing
	const [payInUnitPricing, setPayInUnitPricing] = useRecoilState(
		PayInUnitPricingState
	);
	// Retrieve the current value of FundNameState from Recoil
	const fundNameState = useRecoilValue(FundNameState);

	// hooks
	const { setSessionDetails, sessionPayloadDetail } = useNextStep();

	// Memoize `unit` and `pricePerUnit` to prevent unnecessary re-renders
	const { unit, pricePerUnit } = useMemo(
		() => payInUnitPricing,
		[payInUnitPricing]
	);

	// Memoize and extract fundName from sessionPayloadDetail to avoid unnecessary re-renders
	const { fundName } = useMemo(
		() => sessionPayloadDetail,
		[sessionPayloadDetail]
	);

	// Handle unit input change with validation for positive integers only
	const handleUnitChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			const { value } = e.target;
			// Ensure value is a positive integer or empty
			if (value && !/^[1-9]\d{0,19}$/.test(value)) return;

			// Update unit in Recoil state
			setPayInUnitPricing(prev => ({ ...prev, unit: value }));

			// Update session details with the calculated investing amount
			setSessionDetails(prev => ({
				...prev,
				investingAmount: Number(value) * Number(pricePerUnit),
			}));
		},
		[pricePerUnit, setPayInUnitPricing, setSessionDetails]
	);

	// Calculate the total amount based on the unit and unit price
	const totalAmount = useMemo(
		() => Number(unit) * Number(pricePerUnit),
		[unit, pricePerUnit]
	);

	// Disable proceed button if totalAmount is invalid or out of allowed range
	const isDisabled = useMemo(
		() =>
			!totalAmount ||
			totalAmount <= MIN_DEFAULT_AMOUNT ||
			totalAmount > MAX_DEFAULT_AMOUNT,
		[totalAmount]
	);

	// Check if totalAmount is within the invalid range and show an error
	const checkError = useMemo(
		() =>
			!!totalAmount &&
			(totalAmount <= MIN_DEFAULT_AMOUNT || totalAmount > MAX_DEFAULT_AMOUNT),
		[totalAmount]
	);

	return (
		<div className="UnitPrice__wrapper">
			{/* Header Section */}
			<div className="UnitPrice__header">
				<div className="UnitPrice__title">Number of units required</div>
				<div className="UnitPrice__description">
					Enter the number of units you need below. Total price will be
					calculated automatically, and you can complete payment on the next
					screen.
				</div>
			</div>

			{/* Fund Details */}
			<div className="UnitPrice__fund-details">
				<span className="UnitPrice__fund-label">Fund Account :</span>
				<span className="UnitPrice__fund-name">
					{fundName || fundNameState || '--'}
				</span>
			</div>

			{/* Unit Input Field */}
			<Input
				inputType="text"
				handleChange={handleUnitChange}
				label="Number of Units"
				placeholder=""
				value={unit}
				isRequired
				isError={checkError}
			/>

			{/* Summary Section */}
			<div className="UnitPrice__summary">
				<div className="UnitPrice__summary-row">
					<span className="UnitPrice__row-label">Unit Price (USD)</span> :
					<span className="UnitPrice__row-value">
						${convertToCurrencySystem(pricePerUnit)}/Unit
					</span>
				</div>
				<div className="UnitPrice__summary-row">
					<span className="UnitPrice__row-label">Number of Units</span>:
					<span className="UnitPrice__row-value">{unit || 0}</span>
				</div>
				<div className="UnitPrice__summary-row UnitPrice__total">
					<span className="UnitPrice__row-label">Total Investment Amount</span>:
					<span className="UnitPrice__row-value">
						${convertToCurrencySystem(totalAmount)}
					</span>
				</div>
			</div>

			{/* Error Message for Invalid Total Amount */}
			{checkError && (
				<div className="input__error">
					Total Investment Amount should be greater than $0.5 and less than $1M
				</div>
			)}

			{/* Proceed Button */}
			<div className="fund--button">
				<Button
					label="Proceed"
					handleClick={handleConnect}
					type="button__filled button__filled--primary button__large button__block"
					disabled={isDisabled}
				/>
			</div>
		</div>
	);
};
