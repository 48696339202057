import { Button, Image, Loader } from '@storybook';
import { useMemo, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import './palm-information.scss';
import {
	PalmHandTypes,
	PalmStepVerifiactionMessage,
} from './constant/information';
import {
	ActivePalmHandTypeState,
	activePalmScreenState,
} from 'views/palm-enroll/states/palm-screen-state';

export const PalmLivenessInformation = () => {
	const setActiveScreen = useSetRecoilState(activePalmScreenState);
	const [activePalmHandType, setActivePalmHandType] = useRecoilState(
		ActivePalmHandTypeState
	);
	const [click, setClick] = useState(false);
	// const livenessVideoRef = useRef<HTMLVideoElement | null>(null);
	const handleOnClick = () => {
		setClick(prevClick => !prevClick);
		setActiveScreen('palm-enroll');
	};

	const renderPalmHandTypes = useMemo(() => {
		return PalmHandTypes.map(item => (
			<div
				key={item.type}
				onClick={() => setActivePalmHandType(item.type)}
				className={`select-hand--card ${item.type === activePalmHandType ? 'active-palm-type' : ''}`}
			>
				<div className="select-hand--card__heading">{item.Label}</div>
				<Image url={item.imageUrl} className="palm-hand-type-image" />
				{item.type === activePalmHandType && (
					<div className="selected-palm-hand">Selected</div>
				)}
			</div>
		));
	}, [activePalmHandType, setActivePalmHandType]);

	return (
		<div className="PalmLivenessInformation--container">
			<div className="PalmLivenessInformation--container__content">
				<h2 className="PalmLivenessInformation--container__content__heading">
					{PalmStepVerifiactionMessage.HEADING}
				</h2>

				<p className="PalmLivenessInformation--container__content__subheading">
					{PalmStepVerifiactionMessage.SUBHEADING}
				</p>
				<ul className="PalmLivenessInformation--container__content__list">
					<li className="PalmLivenessInformation--container__content__list__list-item">
						{PalmStepVerifiactionMessage.STEPONE}
					</li>
					<li className="PalmLivenessInformation--container__content__list__list-item">
						{PalmStepVerifiactionMessage.STEPTWO}
					</li>
					<li className="PalmLivenessInformation--container__content__list__list-item">
						{PalmStepVerifiactionMessage.STEPTHREE}
					</li>
					<li className="PalmLivenessInformation--container__content__list__list-item">
						{PalmStepVerifiactionMessage.STEPFOUR}
					</li>
				</ul>
			</div>
			{/* <video
				ref={livenessVideoRef}
				className="PalmLivenessInformation--container__imageinfo"
				autoPlay
				preload="auto"
				loop
				muted
				controls={false}
				playsInline
			>
				<source src={LeftRight_URL} type="video/mp4" />
			</video> */}

			<div className="PalmLivenessInformation--container__selectHand-type-wrapper">
				<div className="select-hand-box">{renderPalmHandTypes}</div>
			</div>

			<div className="PalmLivenessInformation__button--info LivenessInformation__button-begin-liveness">
				<Button
					label="Begin Palm Enrollment"
					type="button__filled button__filled--primary button__large button__block "
					handleClick={handleOnClick}
					loader={click ? <Loader type="loader" dimension={18} /> : <></>}
				/>
			</div>
		</div>
	);
};
