import { Button, IOption, Loader, ReactDropdown } from '@storybook';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { KycSelectedCountry, ShowKycSelectCountry } from '../../store';
import { API_TYPE, APIS } from 'constants/api';
import { THE_KYB_PROVIDER_TOKEN } from 'views/kyb/constants';
import { useSharedVariables, useTokenSession } from 'hooks';
import useFetchWithToken from 'hooks/use-fetch-with-token/use-fetch-with-token';
import {
	CountryStateListState,
	CustomOptionDropdownProps,
} from 'views/kyb/stores';
import { formatValue } from 'utils';
import './kyc-select-country.scss';
import { AccessTokenState } from 'states';

export const KycSelectCountry = () => {
	const { envHost } = useSharedVariables();
	const { fetchData } = useFetchWithToken();
	const { postTokenSession } = useTokenSession();
	const { code: sessionCode } = useRecoilValue(AccessTokenState);

	const [countryListResp, setCountryListResp] = useRecoilState(
		CountryStateListState
	);
	const [kycSelectedCountry, setKycSelectedCountry] =
		useRecoilState(KycSelectedCountry);
	const setShowKycSelectCountry = useSetRecoilState(ShowKycSelectCountry);

	const [selectedCountry, setSelectedCountry] = useState<any>({
		label: 'Select Country',
		value: '',
	});
	const [countriesList, setCountriesList] = useState<IOption[]>([]);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (kycSelectedCountry?.name) {
			setSelectedCountry({
				label: kycSelectedCountry?.name,
				value: kycSelectedCountry,
			});
		}
	}, [kycSelectedCountry]);

	const setFormatedCountryData = useCallback((data: any) => {
		const countryList = data?.map((country: { name: string }) => ({
			label: formatValue(country?.name ?? '--'),
			value: country?.name,
		}));
		setCountriesList(countryList);
	}, []);

	const fetchCountryList = useCallback(async () => {
		try {
			const response = await fetchData(
				APIS.COUNTRY_LIST,
				THE_KYB_PROVIDER_TOKEN[envHost as keyof typeof THE_KYB_PROVIDER_TOKEN]
			);

			if (response?.message === 'ok') {
				const { data } = response;
				setCountryListResp(data);
				setFormatedCountryData(data);
			}

			// Do something with the response
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error('Error fetching data:', error);
		}
	}, [envHost, fetchData, setCountryListResp, setFormatedCountryData]);

	const getCountryDocuments = useCallback(async () => {
		setIsLoading(true);
		try {
			const resp = await postTokenSession({
				type: API_TYPE.KYC_COUNTRY_DOCS,
				code: sessionCode,
			});
			setIsLoading(false);
			return resp;
		} catch (error) {
			setIsLoading(false);
			return error;
		}
	}, [postTokenSession, sessionCode]);

	useEffect(() => {
		if (countryListResp?.length === 0) {
			fetchCountryList();
		} else {
			setFormatedCountryData(countryListResp);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [countryListResp]);

	const handleSubmitCountry = useCallback(async () => {
		const response = await getCountryDocuments();
		const countryDocList = Object.keys(response ?? {}).map((el: string) => ({
			...response[el],
		}));
		const { documents } = countryDocList.find(
			(el: any) =>
				el?.name?.includes(selectedCountry?.value?.name) ||
				selectedCountry?.value?.name?.includes(el?.name)
		);
		setKycSelectedCountry({ ...selectedCountry?.value, documents });
		setShowKycSelectCountry(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedCountry?.value]);

	const getCountryeDetails = useCallback(
		(value: string) => {
			return countryListResp.find(country => country?.name === value);
		},
		[countryListResp]
	);

	const getCustomOptions = useCallback(
		({ children, innerProps }: CustomOptionDropdownProps) => {
			const value = getCountryeDetails(children);
			return (
				<div className="kyc-select-country__dropdown--option" {...innerProps}>
					<span className="kyc-select-country__dropdown--option-flag">
						{value?.logo}
					</span>{' '}
					{children}
				</div>
			);
		},
		[getCountryeDetails]
	);

	const handleOnChange = useCallback(
		(option: IOption) => {
			const selectedCountry = getCountryeDetails(option?.value);
			setSelectedCountry({ ...option, value: selectedCountry });
		},
		[getCountryeDetails]
	);

	const btnLabel = useMemo(() => {
		if (isLoading) {
			return <Loader type="loader" dimension={20} />;
		} else {
			return 'Continue';
		}
	}, [isLoading]);

	return (
		<div className="kyc-select-country">
			<div className="kyc-select-country__container">
				<div className="kyc-select-country__container__title-container">
					<h5 className="kyc-select-country__container__title-container__label">
						Identity Verification
					</h5>
					<h3 className="kyc-select-country__container__title-container__title">
						Select document issuing country
					</h3>
					<p className="kyc-select-country__container__title-container__sub-title">
						Please select document issuing country from the dropdown list below
						to proceed with the verification process.
					</p>
				</div>
				<div className="kyc-select-country__container__country-container">
					<ReactDropdown
						options={countriesList}
						isSearchable={true}
						handleChangeSelect={handleOnChange}
						value={selectedCountry}
						placeholder="Select Country"
						key="Country"
						getCustomOptions={getCustomOptions}
					/>
				</div>
			</div>
			<div className="kyc-select-country__btn-container">
				<Button
					label={btnLabel}
					handleClick={() => handleSubmitCountry()}
					disabled={!selectedCountry?.value || isLoading}
					type="button__filled button__filled--primary button__large button__block"
				/>
			</div>
		</div>
	);
};
