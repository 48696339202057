import { FC } from 'react';

import './body-wrapper.scss';

interface IBodyWrapper {
	label?: JSX.Element;
	headerElement?: JSX.Element;
	bodyElement?: JSX.Element;
	footerElement?: JSX.Element;
	optionalClassName?: string;
	optionalClassNameBody?: string;
	SearchInputElement?: JSX.Element;
}
export const BodyWrapper: FC<IBodyWrapper> = ({
	label,
	headerElement,
	footerElement,
	bodyElement,
	optionalClassName = '',
	optionalClassNameBody = '',
	SearchInputElement,
}) => {
	return (
		<div className={`ppl-screen-body ${optionalClassName}`}>
			{label}
			<div className={`ppl-screen-body__body ${optionalClassNameBody}`}>
				{headerElement}
				{SearchInputElement}
				{bodyElement}
				{footerElement}
			</div>
		</div>
	);
};
