import { ChangeEvent, FC, KeyboardEvent } from 'react';
import { Button, CountryCode } from '@storybook';

import './input.scss';

interface IInput {
	label: string | JSX.Element;
	inputType: 'text' | 'number' | 'password' | 'date';
	placeholder: string;
	handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
	value?: string | number;
	isCountryCodeVisible?: boolean;
	handleChangeCountry?: (e: any) => void;
	isShowButton?: boolean;
	handleClickBtn?: () => void;
	errorMessage?: string;
	isError?: boolean;
	handleClearField?: () => void;
	handleKeyPress?: (e: KeyboardEvent<HTMLInputElement>) => void;
	allowClear?: boolean;
	id?: string;
	handleBlur?: () => void;
	prefixIcon?: string;
	disabled?: boolean;
	suffixIcon?: string;
	handleSuffixIcon?: () => void;
	autoComplete?: string;
	countryCode?: string;
	inputName?: string;
	height?: string;
	isRequired?: boolean;
	inputRef?: any;
	minDate?: string;
	maxDate?: string;
	onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
	pattern?: string;
	onPaste?: (e: React.ClipboardEvent<HTMLInputElement>) => void;
	className?: string;
	maxLength?: number;
	readOnly?: boolean;
}

export const Input: FC<IInput> = ({
	label,
	inputType,
	id,
	placeholder,
	handleChange,
	value,
	handleKeyPress,
	allowClear = false,
	handleClearField,
	isError = false,
	errorMessage,
	isShowButton,
	handleClickBtn,
	isCountryCodeVisible,
	handleChangeCountry,
	handleBlur = () => {},
	prefixIcon,
	disabled = false,
	suffixIcon,
	handleSuffixIcon,
	countryCode,
	autoComplete,
	inputName,
	height = '52px',
	isRequired = false,
	inputRef,
	minDate = '',
	maxDate = '',
	onKeyDown,
	pattern,
	onPaste,
	className,
	maxLength,
	readOnly = false,
}) => {
	return (
		<div className="input" style={{ height }}>
			{label && (
				<label htmlFor={id} className="input__label">
					{label}
					{isRequired && <span>*</span>}
				</label>
			)}
			<div className={`input__value-container ${className}`}>
				<div className="input__group">
					<div
						className={`input__text-field input__text-field${
							isError ? '--error' : ''
						}`}
					>
						{isCountryCodeVisible && handleChangeCountry && (
							<CountryCode
								handleChangeCountry={handleChangeCountry}
								countryCode={countryCode}
								isDisabled={disabled}
							/>
						)}
						{prefixIcon && <i className={prefixIcon} />}
						<input
							placeholder={placeholder}
							type={inputType}
							name={inputName}
							onBlur={handleBlur}
							id={id}
							className="input__field"
							onChange={handleChange}
							value={value}
							onKeyPress={handleKeyPress}
							disabled={disabled}
							autoComplete={autoComplete}
							ref={inputRef}
							min={minDate}
							max={maxDate}
							onKeyDown={onKeyDown}
							pattern={pattern}
							onPaste={onPaste}
							maxLength={maxLength}
							readOnly={readOnly}
						/>
						{allowClear && (
							<i
								className="ri-close-line input__delete"
								onClick={handleClearField}
								onKeyDown={() => ({})}
							/>
						)}
						{suffixIcon && (
							<i
								className={`${suffixIcon} input__view`}
								onClick={handleSuffixIcon}
								onKeyDown={() => ({})}
							/>
						)}
					</div>
					{isShowButton && handleClickBtn && (
						<Button
							label="Add"
							handleClick={handleClickBtn}
							type="button__outline button__large"
						/>
					)}
				</div>

				{isError && errorMessage && (
					<span className="input__error">{errorMessage}</span>
				)}
			</div>
		</div>
	);
};
