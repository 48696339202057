import { useCallback, useMemo, useState } from 'react';
import {
	useRecoilState,
	useRecoilValue,
	useResetRecoilState,
	useSetRecoilState,
} from 'recoil';

import { APIS, API_TYPE } from 'constants/api';
import {
	useNetwork,
	useNextStep,
	useSharedVariables,
	useUrl,
	useWebWorker,
	useTokenSession,
	useNotification,
} from 'hooks';
import {
	AccessTokenState,
	IFormProperties,
	InviteUserData,
	isShowSkipState,
} from 'states';
import { formatValue, removeEmptyKeys } from 'utils';
import {
	IDMeritStepState,
	IsBackupKycState,
	KYCAddressInfo,
	KYCTokenState,
	SsnNumberState,
	KycLoaderState,
	IsKycLoading,
	AddressNotFetchedState,
	IsChequeCompleted,
} from './states';

import { KycSteps } from 'constants/common';
import {
	CountryStateList,
	CountryStateListState,
	TheKYBStateListState,
} from 'views/kyb/stores';
import {
	formPropertiesState,
	KycSelectedCountry,
	selectedDocTypeState,
	SelectedKycDocType,
} from '../components/document-verification-wrapper/store';
import { documentTypeMapping } from '../constants';
import { IidPayload } from './types';

export const useKycRequests = () => {
	const setIsLoading = useSetRecoilState(IsKycLoading);
	const setIsBackupKYC = useSetRecoilState(IsBackupKycState);
	const [formData, setFormData] = useState<any>(null);
	const [isAddressNotFetched, setIsAddressNotFetched] = useRecoilState(
		AddressNotFetchedState
	);
	const setToken = useSetRecoilState(KYCTokenState);
	const selectedDocType = useRecoilValue(selectedDocTypeState);
	const ssnNumber = useRecoilValue(SsnNumberState);
	const setKycLoadersRecoil = useSetRecoilState(KycLoaderState);
	const countryListResp = useRecoilValue(CountryStateListState);
	const kycSelectedCountry = useRecoilValue(KycSelectedCountry);
	const selectedkycDocType = useRecoilValue(SelectedKycDocType);

	const setCurrentStep = useSetRecoilState(IDMeritStepState);
	const [addressDetails, setAddressDetails] = useRecoilState(KYCAddressInfo);
	const setStateList = useSetRecoilState(TheKYBStateListState);
	const [formProperties] = useRecoilState<IFormProperties>(formPropertiesState);
	const { runWorker } = useWebWorker('api-call.js');

	const { handleNext, sessionPayloadDetail, setSessionDetails } = useNextStep();
	const { post } = useNetwork();
	const { code } = useUrl();
	const setIsShowSkip = useSetRecoilState(isShowSkipState);
	const resetkycLoaders = useResetRecoilState(KycLoaderState);
	const resetselectedDocType = useResetRecoilState(selectedDocTypeState);
	const resetFormProperties = useResetRecoilState(formPropertiesState);
	const { token: accessToken, code: sessionCode } =
		useRecoilValue(AccessTokenState);
	const inviteUserData = useRecoilValue(InviteUserData);
	const [isChequeCompleted, setChequeCompleted] =
		useRecoilState(IsChequeCompleted);

	const {
		userId: loginUserId,
		onboardingType,
		apiEndPoint,
	} = useSharedVariables();
	const { postTokenSession } = useTokenSession();
	const { errorNotification } = useNotification();

	const docType = documentTypeMapping[selectedDocType];

	const {
		sessionId,
		_id: pipelineId,
		stepsId,
		userId,
		currentAction,
	} = useMemo(() => sessionPayloadDetail ?? {}, [sessionPayloadDetail]);

	const isSSNAllow = useMemo(
		() =>
			currentAction?.metadata?.SSN ??
			currentAction?.metadata?.ssn ??
			currentAction?.metadata?.metadata?.ssn ??
			false,
		[currentAction?.metadata]
	);

	const isChequeFraud = useMemo(
		() =>
			(currentAction?.metadata?.chequeFraud ||
				currentAction?.metadata?.metadata?.chequeFraud) ??
			false,
		[currentAction?.metadata]
	);

	const isAddressAllow = useMemo(
		() =>
			currentAction?.metadata?.addressVerification ??
			currentAction?.metadata?.metadata?.addressVerification ??
			false,
		[
			currentAction?.metadata?.addressVerification,
			currentAction?.metadata?.metadata?.addressVerification,
		]
	);

	const handleWorker = useCallback(
		(result: any) => {
			if (result?.type === 'kyc') setToken(result?.data);
		},
		[setToken]
	);

	const isComplex = onboardingType === 'complex';

	const getSelectedCountryAndState = useCallback(
		(country: string, state: string) => {
			const selectedCountry: CountryStateList = countryListResp?.find(
				countries =>
					countries.name === country ||
					countries?.iso3?.toLowerCase() === country?.toLowerCase()
			) as CountryStateList;

			const selectedState = (selectedCountry?.states ?? []).find(
				item =>
					item.name === state ||
					item.state_code?.toLowerCase() === state?.toLowerCase()
			);
			const stateList =
				selectedCountry?.states?.map(state => ({
					label: formatValue(state?.name ?? '--'),
					value: state?.name,
				})) ?? [];
			return { selectedCountry, selectedState, stateList };
		},
		[countryListResp]
	);

	const submitSsnAddress = useCallback(async () => {
		const payload: IidPayload = {
			docType,
			sessionId: isComplex ? pipelineId : sessionId,
			nodeId: isComplex ? currentAction?._id : undefined,
			ssn: ssnNumber ?? undefined,
		};
		const updatedObject = removeEmptyKeys(payload);

		if (isComplex && currentAction?._id) {
			updatedObject.nodeId = currentAction._id;
		}

		const isAddressEmpty = Object.values(addressDetails).some(value => value);
		if ((isAddressAllow || isAddressNotFetched) && isAddressEmpty) {
			const { building, country, zipCode, streetAddress, state, city } =
				addressDetails ?? {};

			const { selectedCountry, selectedState } = getSelectedCountryAndState(
				country,
				state
			);
			updatedObject.addressDetails = {
				city,
				country: selectedCountry?.iso3 ?? '',
				zipCode,
				state: selectedState?.state_code ?? '',
				streetAddress: streetAddress || undefined,
				building: building || undefined,
			};
		}
		return await postTokenSession({
			payload: updatedObject,
			type: API_TYPE.KYC,
			code: sessionCode,
		});
	}, [
		addressDetails,
		currentAction._id,
		docType,
		getSelectedCountryAndState,
		isAddressAllow,
		isAddressNotFetched,
		isComplex,
		pipelineId,
		postTokenSession,
		sessionCode,
		sessionId,
		ssnNumber,
	]);

	const linearPatch = useCallback(async () => {
		const payload: any = {
			pipelineId: pipelineId ?? '',
			userId: userId ?? '',
			actions: [
				...(formData?.actions ?? []),
				{
					id: currentAction?.key ?? 'allId',
					data: {},
				},
			],
		};
		payload.stepId = stepsId ?? '';
		setFormData(payload);
		try {
			const res = await postTokenSession({ payload, code: sessionCode });
			if (res?.statusCode !== 200) {
				setIsShowSkip(true);
			}
		} finally {
			handleNext();
		}
	}, [
		currentAction?.key,
		formData?.actions,
		handleNext,
		pipelineId,
		postTokenSession,
		sessionCode,
		setIsShowSkip,
		stepsId,
		userId,
	]);

	const complexPatch = useCallback(async () => {
		const payload: any = {
			pipelineId: pipelineId ?? '',
			userId: userId ?? '',
			actions: [
				...(formData?.actions ?? []),
				{
					id: currentAction?.key ?? 'allId',
					data: {},
				},
			],
			nodeId: currentAction?._id,
		};
		const resp = await postTokenSession({ payload, code: sessionCode });

		const kycResponse = { ...resp };
		delete kycResponse.statusCode;
		setSessionDetails(prev => ({
			...prev,
			nodes: kycResponse,
			steps: [],
		}));
	}, [
		currentAction?._id,
		currentAction?.key,
		formData?.actions,
		pipelineId,
		postTokenSession,
		sessionCode,
		setSessionDetails,
		userId,
	]);

	const trackSession = useCallback(
		async (checkStatus?: any) => {
			setIsBackupKYC(false);
			const id = isComplex ? currentAction?._id : sessionId;
			// TRACK_COMPLEX_SESSION_STATUS and TRACK_SESSION_STATUS get api
			const url = isComplex
				? `${APIS.TOKEN}?nodeId=${id}&sessionId=${sessionId}`
				: `${APIS.TOKEN}?sessionId=${id}&stepId=kyc`;
			const paylaod = {
				code: sessionCode,
				type: API_TYPE.STATUS,
			};
			const resp = await post(url, paylaod);
			if (resp?.[0]) {
				const { stepStatus } = resp['0'];
				if (!checkStatus && stepStatus === 'pending') {
					// Pradeep chaurasia : to call api after every 2 sec
					setTimeout(() => trackSession(), 2000);
					return;
				}
				if (
					stepStatus === 'completed' ||
					stepStatus === 'processing' ||
					stepStatus === 'rejected'
				) {
					if (isComplex) {
						await complexPatch();
						handleNext();
						resetselectedDocType();
						resetFormProperties();
						setTimeout(() => {
							resetkycLoaders();
						}, 2000);
					} else {
						linearPatch();
					}
					clearInterval(checkStatus);
				}
			}
			return resp ?? {};
		},
		[
			setIsBackupKYC,
			isComplex,
			currentAction?._id,
			sessionId,
			sessionCode,
			post,
			complexPatch,
			handleNext,
			resetselectedDocType,
			resetFormProperties,
			resetkycLoaders,
			linearPatch,
		]
	);

	const handleCheckStatus = useCallback(async () => {
		if (sessionPayloadDetail?.sessionId || sessionPayloadDetail?._id) {
			const resp = await trackSession();
			return resp;
		} else return {};
	}, [
		sessionPayloadDetail?._id,
		sessionPayloadDetail?.sessionId,
		trackSession,
	]);

	const submitSsnAndCheckStatus = useCallback(async () => {
		const res = await submitSsnAddress();
		if (res?.statusCode === 400) {
			errorNotification(res?.message);
			setKycLoadersRecoil(false);
		} else if (res?.success === false) {
			const { selectedCountry, selectedState, stateList } =
				getSelectedCountryAndState(res?.address?.country, res?.address?.state);
			setStateList(stateList);
			setAddressDetails({
				...res?.address,
				country: selectedCountry?.name ?? '',
				state: selectedState?.name,
			});
			setIsAddressNotFetched(true);
			setCurrentStep(KycSteps.Address!);
			setKycLoadersRecoil(false);
		} else {
			setKycLoadersRecoil(true);
			await handleCheckStatus();
		}
		return res;
	}, [
		setKycLoadersRecoil,
		submitSsnAddress,
		errorNotification,
		getSelectedCountryAndState,
		setStateList,
		setAddressDetails,
		setIsAddressNotFetched,
		setCurrentStep,
		handleCheckStatus,
	]);

	const submitCheckScan = useCallback(
		async (payload: any) => {
			const dataPayload = {
				sessionId: isComplex ? pipelineId : sessionId,
				...payload,
			};

			await postTokenSession({
				payload: dataPayload,
				type: API_TYPE.COMPLEX_SESSION_NODE,
				code: sessionCode,
				nodeId: currentAction._id,
			});
			setChequeCompleted(true);
			setIsBackupKYC(true);
			await submitSsnAndCheckStatus();
		},
		[
			currentAction._id,
			isComplex,
			pipelineId,
			postTokenSession,
			sessionCode,
			sessionId,
			setChequeCompleted,
			setIsBackupKYC,
			submitSsnAndCheckStatus,
		]
	);

	const uploadKycDocument = useCallback(
		async (formData: any) => {
			try {
				const response = await fetch(apiEndPoint + APIS.KYC_DOC_UPLOAD, {
					method: 'POST',
					body: formData,
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
				});
				const apiData = await response.json();
				return { ...apiData, statusCode: response.status };
			} catch (err: any) {
				return null;
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[accessToken, apiEndPoint]
	);

	const submitKycVerification = useCallback(async () => {
		setIsLoading(true);
		setIsBackupKYC(true);
		const isAddressFilled = Object.values(addressDetails).some(value => value);
		if (isSSNAllow && !ssnNumber) {
			setKycLoadersRecoil(false);
			setCurrentStep(KycSteps.Ssn!);
			// Shahbaaz; Due to multiple time cheque screen comming we put state to prevent (This will be  break if when multiple cheque fraud added )
		} else if (isChequeFraud && !isChequeCompleted) {
			setKycLoadersRecoil(false);
			setCurrentStep(KycSteps.CheckScan!);
		} else if (isAddressAllow && !isAddressFilled && isAddressNotFetched) {
			setKycLoadersRecoil(false);
			setCurrentStep(KycSteps.Address!);
		} else {
			await submitSsnAndCheckStatus();
		}
	}, [
		setIsLoading,
		setIsBackupKYC,
		addressDetails,
		isAddressAllow,
		isAddressNotFetched,
		isSSNAllow,
		ssnNumber,
		isChequeFraud,
		setKycLoadersRecoil,
		setCurrentStep,
		submitSsnAndCheckStatus,
		isChequeCompleted,
	]);

	const handleKycDocVerification = useCallback(
		async (side: string) => {
			const kownDocType =
				selectedkycDocType?.name === 'Driving Licence' ||
				selectedkycDocType?.name === 'Passport';
			const document =
				side === 'front'
					? formProperties.frontImagePayload
					: formProperties.backImagePayload;

			const formData = new FormData();
			formData.append('document', document);
			formData.append('docType', kownDocType ? docType : 'unknown');
			formData.append('side', side);
			formData.append('code', sessionCode as string);
			formData.append('country', kycSelectedCountry?.iso3);
			if (isComplex) {
				formData.append('nodeId', currentAction?._id);
			}

			const resp = await uploadKycDocument(formData);
			if (resp?.statusCode == 400) return resp;
			if (resp?.message) {
				errorNotification(resp?.message);
			}
			if (!!formProperties.backImage || selectedkycDocType?.noOfSides === '1') {
				await submitKycVerification();
			}
		},
		[
			currentAction?._id,
			docType,
			errorNotification,
			formProperties,
			isComplex,
			kycSelectedCountry?.iso3,
			selectedkycDocType,
			sessionCode,
			submitKycVerification,
			uploadKycDocument,
		]
	);

	const onsubmitSSN = useCallback(async () => {
		if (isChequeFraud) {
			setCurrentStep(KycSteps.CheckScan!);
		} else {
			return await submitSsnAndCheckStatus();
		}
	}, [isChequeFraud, setCurrentStep, submitSsnAndCheckStatus]);

	const requestTokenWebWorker = useCallback(() => {
		const info = {
			api: `${apiEndPoint}/${APIS.TOKEN}`,
			payload: {
				code: sessionCode,
				type: API_TYPE.REQUEST_URL,
				payload: {
					code: inviteUserData.code ?? code,
					stepId: 'kyc',
					...(loginUserId && { userId: loginUserId }),
				},
			},
			type: 'kyc',
			accessToken: accessToken,
		};
		runWorker?.(info, handleWorker);
	}, [
		apiEndPoint,
		sessionCode,
		inviteUserData.code,
		code,
		loginUserId,
		accessToken,
		runWorker,
		handleWorker,
	]);

	return {
		onsubmitSSN,
		trackSession,
		requestTokenWebWorker,
		docType,
		isSSNAllow,
		submitCheckScan,
		isAddressAllow,
		isChequeFraud,
		currentAction,
		handleKycDocVerification,
		submitKycVerification,
	};
};
