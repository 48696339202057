import { IUploadFile } from './type';

import { atom } from 'recoil';

import { MLC_LABEL, getSignatoryTypeForm } from '../constants';
import { getCustomComponent } from '../components/custom-form';

export const AccrdCustom506bUrlState = atom<any>({
	key: 'custom-url-506b',
	default: {},
});

export const Accred = atom<any>({
	key: 'accred-506c',
	default: {
		selectOption: {
			type: 'choose-option',
			label: 'Accreditation(506c)',
			title: 'Choose Identity Type',
			subTitle:
				' Please select identity type you would like to submit for accreditation approval.',
			settings: [
				{
					id: 1,
					type: 'list',
					sublabel: 'Myself as an',
					label: 'Individual',
					key: 'individualAccreditationVerify',
				},
				{
					id: 2,
					sublabel: 'Business or Trust as an',
					label: 'Entity',
					type: 'list',
					key: 'entityAccreditationVerify',
				},
			],
		},
		individualAccreditationVerify: {
			type: 'radio-select',
			label: 'Accreditation(506c)',
			title: 'Individual Accreditation',
			subTitle:
				'I would like to verify my status as an accredited investor by confirming',
			back: 'selectOption',
			settings: [
				{
					id: 1,
					type: 'radio',
					key: 'netWorthValidation',
					label:
						'Net worth of over $1 million, excluding primary residence (individually or with spouse or partner).',
				},
				{
					id: 2,
					type: 'radio',
					key: 'incomeValidation',
					label:
						'Income over $200,000 (individually) or $300,000 (with spouse or partner) in each of the prior two years, and reasonably expects the same for the current year.',
				},
				{
					id: 3,
					type: 'radio',
					key: 'ownerOfCompany',
					label: 'I am  a director or executive officer at a company.',
				},
				{
					id: 4,
					type: 'radio',
					key: 'finraUploadDocuments',
					label:
						'Hold a professional license with FINRA that qualifies me as an accredited investor',
				},
				{
					id: 5,
					type: 'radio',
					key: 'qualifiedAccreditedInvestor',
					label:
						'I am a qualified purchaser with minimum $5,000,000 in investments.',
				},
				{
					id: 6,
					type: 'radio',
					key: 'noneOfAboveUploadDocuments',
					label: 'None of the above apply to me',
				},
			],
		},
		incomeValidation: {
			type: 'radio-select',
			label: 'Accreditation(506c)',
			title: 'Income Validation',
			subTitle: 'I would like to verify my income by',
			back: 'individualAccreditationVerify',
			settings: [
				{
					id: 3,
					type: 'radio',
					key: 'incomeValidationSignatoryType',
					customComponent: getCustomComponent('incomeValidationSignatoryType'),
					label: MLC_LABEL,
				},
				{
					id: 1,
					type: 'radio',
					key: 'incomeUploadDocuments',
					label:
						'Uploading copies of tax form containing my salary for the past two years',
				},
				{
					id: 2,
					type: 'radio',
					key: 'incomeUploadDocuments',
					label:
						'Uploading a letter from my lawyer, CPA, or investment advisor verifying my income',
				},
			],
		},
		incomeValidationSignatoryType: getSignatoryTypeForm('incomeValidation'),
		netWorthValidation: {
			type: 'radio-select',
			label: 'Accreditation(506c)',
			title: 'Net Worth Validation',
			subTitle: 'I would like to verify my net worth by',
			back: 'individualAccreditationVerify',
			settings: [
				{
					id: 3,
					type: 'radio',
					key: 'netWorthValidationSignatoryType',
					label: MLC_LABEL,
					customComponent: getCustomComponent(
						'netWorthValidationSignatoryType'
					),
				},
				{
					id: 1,
					type: 'radio',
					key: 'networthUploadDocuments',
					label:
						'Uploading asset document and full credit report showing net worth of at least $1M',
				},
				{
					id: 2,
					type: 'radio',
					key: 'networthUploadDocuments',
					label:
						'Uploading a letter from my lawyer, CPA, or investment advisor verifying my net worth',
				},
			],
		},
		netWorthValidationSignatoryType: getSignatoryTypeForm('netWorthValidation'),
		incomeUploadDocuments: {
			type: 'upload-document',
			label: 'Accreditation(506c)',
			title: 'Upload Documents',
			subTitle: 'Please upload all the relevant documents here.',
			back: 'incomeValidation',
			settings: [
				{
					id: 1,
					type: 'uploadDocuments',
				},
			],
		},
		networthUploadDocuments: {
			type: 'upload-document',
			label: 'Accreditation(506c)',
			title: 'Upload Documents',
			subTitle: 'Please upload all the relevant documents here.',
			back: 'netWorthValidation',
			settings: [
				{
					id: 1,
					type: 'uploadDocuments',
				},
			],
		},
		qualifiedAccreditedInvestor: {
			type: 'radio-select',
			label: 'Accreditation(506c)',
			title: 'Qualified Accredited Investor Validation',
			subTitle:
				'I would like to verify that I am qualified accredited investor by',
			back: 'individualAccreditationVerify',
			settings: [
				{
					id: 2,
					type: 'radio',
					key: 'qualifiedAccreditedInvestorSignatoryType',
					customComponent: getCustomComponent(
						'qualifiedAccreditedInvestorSignatoryType'
					),
					label: MLC_LABEL,
				},
				{
					id: 1,
					type: 'radio',
					key: 'qualifiedAccreditedInvestorUploadDocuments',
					label: 'Upload supporting documents.',
				},
			],
		},
		qualifiedAccreditedInvestorSignatoryType: getSignatoryTypeForm(
			'qualifiedAccreditedInvestor'
		),
		qualifiedAccreditedInvestorUploadDocuments: {
			type: 'upload-document',
			label: 'Accreditation(506c)',
			title: 'Upload Documents',
			subTitle: 'Please upload all the relevant documents here.',
			back: 'qualifiedAccreditedInvestor',
			settings: [
				{
					id: 1,
					type: 'uploadDocuments',
				},
			],
		},
		ownerOfCompany: {
			type: 'radio-select',
			label: 'Accreditation(506c)',
			title: 'Director/Executive Titleship Validation',
			subTitle: 'I would like to verify my titleship by',
			back: 'individualAccreditationVerify',
			settings: [
				{
					id: 3,
					type: 'radio',
					key: 'ownerOfCompanySignatoryType',
					customComponent: getCustomComponent('ownerOfCompanySignatoryType'),
					label: MLC_LABEL,
				},
				{
					id: 1,
					type: 'radio',
					key: 'ownerOfCompanyUploadDocuments',
					label: 'Uploading document of titleship of the company.',
				},
				{
					id: 2,
					type: 'radio',
					key: 'ownerOfCompanyUploadDocuments',
					label:
						'Uploading a letter from my lawyer, CPA, or investment advisor verifying my titleship.',
				},
			],
		},
		ownerOfCompanySignatoryType: getSignatoryTypeForm('ownerOfCompany'),
		ownerOfCompanyUploadDocuments: {
			type: 'upload-document',
			label: 'Accreditation(506c)',
			title: 'Upload Documents',
			subTitle: 'Please upload all the relevant documents here.',
			back: 'ownerOfCompany',
			settings: [
				{
					id: 1,
					type: 'uploadDocuments',
				},
			],
		},
		finraUploadDocuments: {
			type: 'input-form',
			label: 'Accreditation(506c)',
			title: 'Professional License Verification',
			subTitle:
				'I would like to verify my professional license by providing my SEC approved license CRD number as listed on ',
			back: 'individualAccreditationVerify',
			settings: [
				{
					id: 3,
					type: 'checkbox',
					key: 'networthUploadDocuments',
					title:
						'I certify the CRD number I am providing is correct and that I am in good standing with my FINRA member firm.',
				},
				{
					id: 1,
					type: 'dropdown',
					key: 'license',
					name: 'license',
					title: 'SEC Approved License',
				},
				{
					id: 2,
					type: 'input',
					key: 'crdNumber',
					name: 'crdNumber',
					placeholder: 'Enter Number',
					title: 'CRD Number',
				},
			],
		},
		noneOfAboveUploadDocuments: {
			type: 'upload-document',
			label: 'Accreditation(506c)',
			title: 'Upload Documents',
			subTitle: 'Please upload all the relevant documents here.',
			back: 'individualAccreditationVerify',
			settings: [
				{
					id: 1,
					type: 'uploadDocuments',
				},
			],
		},
		entityAccreditationVerify: {
			type: 'radio-select',
			label: 'Accreditation(506c)',
			title: 'Entity Accreditation',
			subTitle:
				'I would like to verify  my entity as accredited investor by confirming',
			back: 'selectOption',
			settings: [
				{
					id: 1,
					type: 'radio',
					key: 'corporationPartnership',
					label:
						'It is a corporation, partnership, business trust or a nonprofit organization within the meaning of Section 501(c)(3) of the Internal Revenue Code of 1986, as amended, that was not formed for the specific purpose of acquiring the securities offered and that has total assets in excess of $5,000,000.',
				},
				{
					id: 2,
					type: 'radio',
					label:
						'It is a bank, savings and loan association or credit union, insurance company, registered investment company, registered business development company, licensed small business investment company, or employee benefit plan within the meaning of Title 1 of ERISA whose plan fiduciary is either a bank, insurance company or registered investment advisor or whose total assets exceed $5,000,000.',
					key: 'bankSavingsLoanAssociation',
				},
				{
					id: 3,
					type: 'radio',
					key: 'entityCommonUpload',
					label:
						'It is a private business development company as defined in Section 202(a)(22) of the Investment Advisors Act of 1940.',
				},
				{
					id: 4,
					type: 'radio',
					key: 'underSignedTrust',
					label:
						'The undersigned is a trust with total assets in excess of $5,000,000, not formed for the specific purpose of acquiring the securities offered, whose purchase is directed by a sophisticated person (a person who either alone or with his or her purchaser representative(s) has such knowledge and experience in financial and business matters that he or she is capable of evaluating the merits and risks of the prospective investment). A copy of the declaration of trust or trust agreement and a representation as to the sophistication of the person directing purchases for the trust is enclosed.',
				},
				{
					id: 5,
					type: 'radio',
					key: 'selfDirectedEmployee',
					label:
						'It is a self directed employee benefit plan for which all persons making investment decisions are “accredited investors” within one or more of the categories described above.',
				},
				{
					id: 6,
					type: 'radio',
					label:
						'It is an entity in which all of the equity owners are “accredited investors” within one or more of the categories described above.',
					key: 'allEquityOwners',
				},
				{
					id: 7,
					type: 'radio',
					secondKey: '',
					label: 'Qualified purchaser',
					key: 'qualifiedPurchaserEntity',
				},
				{
					id: 8,
					type: 'radio',
					key: 'entityNonOfAbowUpload',
					label: 'None of the above apply to me',
				},
			],
		},
		qualifiedPurchaserEntity: {
			type: 'radio-select',
			label: 'Accreditation(506c)',
			title: 'Qualified Purchaser',
			subTitle:
				'I would like to verify  my entity as accredited investor by confirming',
			back: 'entityAccreditationVerify',
			settings: [
				{
					id: 5,
					type: 'radio',
					key: 'qualifiedPurchaserSignatoryType',
					customComponent: getCustomComponent(
						'qualifiedPurchaserSignatoryType'
					),
					label: MLC_LABEL,
				},
				{
					id: 1,
					type: 'radio',
					key: 'qualifiedPurchaserUploadDocuments',
					label:
						'It is an family-owned business that has at least $5 million in investments.',
				},
				{
					id: 2,
					type: 'radio',
					key: 'qualifiedPurchaserUploadDocuments',
					label:
						'It is a trust that is managed and sponsored by qualified purchasers with at least $5 million in investments.',
				},
				{
					id: 3,
					type: 'radio',
					key: 'qualifiedPurchaserUploadDocuments',
					label:
						'It is an entity that invests at least $25 million on its own behalf or the behalf of others.',
				},
				{
					id: 4,
					type: 'radio',
					key: 'qualifiedPurchaserUploadDocuments',
					label: 'It is an entity owned entirely by qualified purchasers.',
				},
			],
		},
		qualifiedPurchaserSignatoryType: getSignatoryTypeForm(
			'qualifiedPurchaserEntity'
		),
		qualifiedPurchaserUploadDocuments: {
			type: 'upload-document',
			label: 'Accreditation(506c)',
			title: 'Upload Documents',
			subTitle: 'Please upload all the relevant documents here.',
			back: 'qualifiedPurchaserEntity',
			settings: [
				{
					id: 1,
					type: 'uploadDocuments',
				},
			],
		},
		corporationPartnership: {
			type: 'radio-select',
			label: 'Accreditation(506c)',
			title: 'Corporation Partnership',
			subTitle:
				'I would like to verify  my entity as Corporation Partnership investor by',
			back: 'entityAccreditationVerify',
			settings: [
				{
					id: 2,
					type: 'radio',
					key: 'corporationPartnershipSignatoryType',
					customComponent: getCustomComponent(
						'corporationPartnershipSignatoryType'
					),
					label: MLC_LABEL,
				},
				{
					id: 1,
					type: 'radio',
					key: 'corporationPartnershipUpload',
					label: 'Upload supporting documents.',
				},
			],
		},
		corporationPartnershipSignatoryType: getSignatoryTypeForm(
			'corporationPartnership'
		),
		corporationPartnershipUpload: {
			type: 'upload-document',
			label: 'Accreditation(506c)',
			title: 'Upload Documents',
			subTitle: 'Please upload all the relevant documents here.',
			back: 'corporationPartnership',
			settings: [
				{
					id: 1,
					type: 'uploadDocuments',
				},
			],
		},
		selfDirectedEmployee: {
			type: 'radio-select',
			label: 'Accreditation(506c)',
			title: 'Self Directed Employee',
			subTitle:
				'I would like to verify  my entity as Self Directed Employee investor by',
			back: 'entityAccreditationVerify',
			settings: [
				{
					id: 2,
					type: 'radio',
					key: 'selfDirectedEmployeeSignatoryType',
					customComponent: getCustomComponent(
						'selfDirectedEmployeeSignatoryType'
					),
					label: MLC_LABEL,
				},
				{
					id: 1,
					type: 'radio',
					key: 'selfDirectedEmployeeUpload',
					label: 'Upload supporting documents.',
				},
			],
		},
		selfDirectedEmployeeSignatoryType: getSignatoryTypeForm(
			'selfDirectedEmployee'
		),
		selfDirectedEmployeeUpload: {
			type: 'upload-document',
			label: 'Accreditation(506c)',
			title: 'Upload Documents',
			subTitle: 'Please upload all the relevant documents here.',
			back: 'selfDirectedEmployee',
			settings: [
				{
					id: 1,
					type: 'uploadDocuments',
				},
			],
		},
		bankSavingsLoanAssociation: {
			type: 'radio-select',
			label: 'Accreditation(506c)',
			title:
				'Bank, savings and loan association or credit union, insurance company',
			subTitle:
				'I would like to verify  my entity as bank, savings and loan association or credit union, insurance company investor by',
			back: 'entityAccreditationVerify',
			settings: [
				{
					id: 2,
					type: 'radio',
					key: 'bankSavingsLoanAssociationSignatoryType',
					customComponent: getCustomComponent(
						'bankSavingsLoanAssociationSignatoryType'
					),
					label: MLC_LABEL,
				},
				{
					id: 1,
					type: 'radio',
					key: 'bankSavingsLoanAssociationUpload',
					label: 'Upload supporting documents.',
				},
			],
		},
		bankSavingsLoanAssociationSignatoryType: getSignatoryTypeForm(
			'bankSavingsLoanAssociation'
		),
		bankSavingsLoanAssociationUpload: {
			type: 'upload-document',
			label: 'Accreditation(506c)',
			title: 'Upload Documents',
			subTitle: 'Please upload all the relevant documents here.',
			back: 'bankSavingsLoanAssociation',
			settings: [
				{
					id: 1,
					type: 'uploadDocuments',
				},
			],
		},
		allEquityOwners: {
			type: 'radio-select',
			label: 'Accreditation(506c)',
			title:
				'All of the equity owners are “accredited investors” within one or more of the categories',
			subTitle:
				'I would like to verify  my entity in which all of the equity owners are “accredited investors” within one or more of the categories by',
			back: 'entityAccreditationVerify',
			settings: [
				{
					id: 2,
					type: 'radio',
					key: 'allEquityOwnersSignatoryType',
					customComponent: getCustomComponent('allEquityOwnersSignatoryType'),
					label: MLC_LABEL,
				},
				{
					id: 1,
					type: 'radio',
					key: 'allEquityOwnersUpload',
					label: 'Upload supporting documents.',
				},
			],
		},
		allEquityOwnersSignatoryType: getSignatoryTypeForm('allEquityOwners'),
		allEquityOwnersUpload: {
			type: 'upload-document',
			label: 'Accreditation(506c)',
			title: 'Upload Documents',
			subTitle: 'Please upload all the relevant documents here.',
			back: 'allEquityOwners',
			settings: [
				{
					id: 1,
					type: 'uploadDocuments',
				},
			],
		},
		underSignedTrust: {
			type: 'radio-select',
			label: 'Accreditation(506c)',
			title: 'Undersigned Trust',
			subTitle:
				'I would like to verify  my entity as Undersigned trust investor by',
			back: 'entityAccreditationVerify',
			settings: [
				{
					id: 2,
					type: 'radio',
					key: 'underSignedTrustSignatoryType',
					customComponent: getCustomComponent('underSignedTrustSignatoryType'),
					label: MLC_LABEL,
				},
				{
					id: 1,
					type: 'radio',
					key: 'underSignedTrustUpload',
					label: 'Upload supporting documents.',
				},
			],
		},
		underSignedTrustUpload: {
			type: 'upload-document',
			label: 'Accreditation(506c)',
			title: 'Upload Documents',
			subTitle: 'Please upload all the relevant documents here.',
			back: 'underSignedTrust',
			settings: [
				{
					id: 1,
					type: 'uploadDocuments',
				},
			],
		},
		entityNonOfAbowUpload: {
			type: 'upload-document',
			label: 'Accreditation(506c)',
			title: 'Upload Documents',
			subTitle: 'Please upload all the relevant documents here.',
			back: 'entityAccreditationVerify',
			settings: [
				{
					id: 1,
					type: 'uploadDocuments',
				},
			],
		},
		entityCommonUpload: {
			type: 'upload-document',
			label: 'Accreditation(506c)',
			title: 'Upload Documents',
			subTitle: 'Please upload all the relevant documents here.',
			back: 'entityAccreditationVerify',
			settings: [
				{
					id: 1,
					type: 'uploadDocuments',
				},
			],
		},
	},
});

export const Accred506b = atom<{
	[key: string]: any;
}>({
	key: 'accred-506b',
	default: {
		selectOption: {
			type: 'choose-option',
			label: 'Accreditation(506c)',
			key: 'accreditationVerify',
			title: 'Choose Identity Type',
			subTitle:
				' Please select identity type you would like to submit for accreditation approval.',
			settings: [
				{
					id: 1,
					type: 'list',
					sublabel: 'Myself as an',
					label: 'Individual',
					key: 'individualAccreditationVerify',
				},
				{
					id: 2,
					sublabel: 'Business or Trust as an',
					label: 'Entity',
					type: 'list',
					key: 'entityAccreditationForm',
				},
			],
		},
		entityAccreditationForm: {
			type: 'input-form',
			label: 'Accreditation(506c)',
			key: 'accreditationVerify',
			title: 'Entity Accreditation Form',
			back: 'selectOption',
			subTitle: 'Please provide these basic information about your entity',
			settings: [
				{
					label: 'Name of the Entity',
					type: 'input',
					name: 'entityName',
					placeholder: 'Entity Name',
				},
				{
					label: 'Name ',
					type: 'input',
					name: 'name',
					placeholder: 'Enter Your Name',
				},
				{
					label: 'Title/Designation',
					type: 'input',
					name: 'designation',
					placeholder: 'Enter Your Title or Designation',
				},
			],
		},
		individualAccreditationVerify: {
			type: 'radio-select',
			label: 'Accreditation(506c)',
			key: 'accreditationVerify',
			title: 'Individual Accreditation',
			subTitle:
				'I would like to verify my status as an accredited investor by confirming',
			back: 'selectOption',
			settings: [
				{
					id: 1,
					type: 'radio',
					key: 'netWorthValidation',
					label:
						'Net worth of over $1 million, excluding primary residence (individually or with spouse or partner).',
				},
				{
					id: 2,
					type: 'radio',
					key: 'incomeValidation',
					label:
						'Income over $200,000 (individually) or $300,000 (with spouse or partner) in each of the prior two years, and reasonably expects the same for the current year.',
				},
				{
					id: 3,
					type: 'radio',
					key: 'ownerOfCompany',
					label: 'I’m a director or executive officer at a company.',
				},
				{
					id: 4,
					type: 'radio',
					key: 'finraUploadDocuments',
					label:
						'Hold a professional license with FINRA that qualifies me as an accredited investor',
				},
				{
					id: 5,
					type: 'radio',
					key: 'noneOfAboveUploadDocuments',
					label: 'None of the above apply to me',
				},
			],
		},
		incomeValidation: {
			label: 'Accreditation(506c)',
			key: 'accreditationVerify',
			title: 'Income Validation',
			subTitle: 'I would like to verify my income by',
			back: 'individualAccreditationVerify',
			settings: [
				{
					id: 9,
					type: 'radio',
					key: 'incomeUploadDocuments',
					label:
						'Uploading copies of tax form containing my salary for the past two years',
				},
				{
					id: 10,
					type: 'radio',
					key: 'incomeUploadDocuments',
					label:
						'Uploading a letter from my lawyer, CPA, or investment advisor verifying my income',
				},

				{
					id: 11,
					type: 'button',
					key: 'incomeUploadDocumentsNext',
					label: 'Next',
				},
				{
					id: 12,
					type: 'button',
					key: 'incomeUploadDocumentsBack',
					label: 'Back',
				},
			],
		},
		netWorthValidation: {
			label: 'Accreditation(506c)',
			key: 'accreditationVerify',
			title: 'Net Worth Validation',
			subTitle: 'I would like to verify my net worth by',
			back: 'individualAccreditationVerify',
			settings: [
				{
					id: 13,
					type: 'radio',
					key: 'networthUploadDocuments',
					label:
						'Uploading asset document and full credit report showing net worth of at least $1M',
				},
				{
					id: 14,
					type: 'radio',
					key: 'networthUploadDocuments',
					label:
						'Uploading a letter from my lawyer, CPA, or investment advisor verifying my net worth',
				},
				{
					id: 15,
					type: 'button',
					key: 'networthUploadDocumentsNext',
					label: 'Next',
				},
				{
					id: 16,
					type: 'button',
					key: 'networthUploadDocumentsBack',
					label: 'Back',
				},
			],
		},
		incomeUploadDocuments: {
			label: 'Accreditation(506c)',
			key: 'accreditationVerify',
			title: 'Upload Documents',
			subTitle: 'Please upload all the relevant documents here.',
			back: 'incomeValidation',
			settings: [
				{
					id: 17,
					type: 'uploadDocuments',
				},
			],
		},
		networthUploadDocuments: {
			label: 'Accreditation(506c)',
			key: 'accreditationVerify',
			title: 'Upload Documents',
			subTitle: 'Please upload all the relevant documents here.',
			back: 'netWorthValidation',
			settings: [
				{
					id: 18,
					type: 'uploadDocuments',
				},
			],
		},
		qualifiedAccreditedInvestor: {
			label: 'Accreditation(506c)',
			key: 'accreditationVerify',
			title: 'Upload Documents',
			subTitle: 'Please upload all the relevant documents here.',
			back: 'individualAccreditationVerify',
			settings: [
				{
					id: 50,
					type: 'uploadDocuments',
				},
			],
		},
		ownerOfCompany: {
			label: 'Accreditation(506c)',
			key: 'accreditationVerify',
			title: 'Director/Executive Titleship Validation',
			subTitle: 'I would like to verify my titleship by',
			back: 'individualAccreditationVerify',
			settings: [
				{
					id: 33,
					type: 'radio',
					key: 'networthUploadDocuments',
					label: 'Uploading document of titleship of the company.',
				},
				{
					id: 34,
					type: 'radio',
					key: 'networthUploadDocuments',
					label:
						'Uploading a letter from my lawyer, CPA, or investment advisor verifying my titleship.',
				},
				{
					id: 15,
					type: 'button',
					key: 'networthUploadDocumentsNext',
					label: 'Next',
				},
				{
					id: 16,
					type: 'button',
					key: 'individualAccreditationVerify',
					label: 'Back',
				},
			],
		},
		finraUploadDocuments: {
			label: 'Accreditation(506c)',
			key: 'accreditationVerify',
			title: 'Professional License Verification',
			subTitle:
				'I would like to verify my professional license by providing my SEC approved license CRD number as listed on {{link}}.',
			back: 'individualAccreditationVerify',
			settings: [
				{
					id: 14,
					type: 'dropdown',
					key: 'license',

					title: 'SEC Approved License',
				},
				{
					id: 14,
					type: 'input',
					key: 'crdNumber',
					placeholder: 'Enter Number',
					title: 'CRD Number',
				},
				{
					id: 14,
					type: 'checkbox',
					key: 'networthUploadDocuments',
				},
				{
					id: 15,
					type: 'button',
					key: 'networthUploadDocumentsNext',
					label: 'Submit',
				},
				{
					id: 16,
					type: 'button',
					key: 'networthUploadDocumentsBack',
					label: 'Back',
				},
			],
		},
		noneOfAboveUploadDocuments: {
			label: 'Accreditation(506c)',
			key: 'accreditationVerify',
			title: 'Upload Documents',
			subTitle: 'Please upload all the relevant documents here.',
			back: 'individualAccreditationVerify',
			settings: [
				{
					id: 20,
					type: 'uploadDocuments',
				},
			],
		},
		commonUploadDocuments: {
			label: 'Accreditation(506c)',
			key: 'accreditationVerify',
			title: 'Upload Documents',
			subTitle: 'Please upload all the relevant documents here.',
			back: 'describeEntityVerify',
			settings: [
				{
					id: 20,
					type: 'uploadDocuments',
				},
			],
		},
		entityAccreditationVerify: {
			type: 'radio-select',
			label: 'Accreditation(506b)',
			key: 'accreditationVerify',
			title: 'Entity Accreditation',
			subTitle:
				'I would like to verify  my entity as accredited investor by confirming',
			back: 'entityAccreditationForm',
			secondBack: 'entityAccreditationForm',
			settings: [
				{
					id: 1,
					type: 'radio',
					key: 'entityCommonUpload',
					label:
						'It is a corporation, partnership, business trust or a nonprofit organization within the meaning of Section 501(c)(3) of the Internal Revenue Code of 1986, as amended, that was not formed for the specific purpose of acquiring the securities offered and that has total assets in excess of $5,000,000.',
				},
				{
					id: 2,
					type: 'radio',
					label:
						'It is a bank, savings and loan association or credit union, insurance company, registered investment company, registered business development company, licensed small business investment company, or employee benefit plan within the meaning of Title 1 of ERISA whose plan fiduciary is either a bank, insurance company or registered investment advisor or whose total assets exceed $5,000,000.',
					key: 'describeEntity',
				},
				{
					id: 3,
					type: 'radio',
					key: 'entityCommonUpload',
					label:
						'It is a private business development company as defined in Section 202(a)(22) of the Investment Advisors Act of 1940.',
				},
				{
					id: 4,
					type: 'radio',
					key: 'entityCommonUpload',
					label:
						'The undersigned is a trust with total assets in excess of $5,000,000, not formed for the specific purpose of acquiring the securities offered, whose purchase is directed by a sophisticated person (a person who either alone or with his or her purchaser representative(s) has such knowledge and experience in financial and business matters that he or she is capable of evaluating the merits and risks of the prospective investment). A copy of the declaration of trust or trust agreement and a representation as to the sophistication of the person directing purchases for the trust is enclosed.',
				},
				{
					id: 5,
					type: 'radio',
					key: 'entityCommonUpload',
					label:
						'It is a self directed employee benefit plan for which all persons making investment decisions are “accredited investors” within one or more of the categories described above.',
				},
				{
					id: 6,
					type: 'radio',
					label:
						'It is an entity in which all of the equity owners are “accredited investors” within one or more of the categories described above.',
					key: 'describeEntity',
				},
				{
					id: 7,
					type: 'radio',
					key: 'entityCommonUpload',
					label: 'None of the above apply to me',
				},
			],
		},
		describeEntity: {
			type: 'input-form',
			label: 'Accreditation(506b)',
			key: 'accreditationVerify',
			title: 'Describe Entity',
			subTitle:
				'Provide a detailed description of your entity, including its purpose, structure, and any relevant details that contribute to a comprehensive understanding.',
			back: 'entityAccreditationVerify',
			settings: [
				{
					id: 1,
					type: 'text-area',
					key: 'describeEntity',
					label: 'Describe Entity',
					name: 'describe',
					placeholder: 'Describe Entity',
				},
			],
		},
		describeEntityEquityOwnerVerify: {
			label: 'Accreditation(506b)',
			key: 'accreditationVerify',
			title: 'Describe Entity As Equity Owner',
			subTitle:
				'I would like to verify  my entity as Describe Entity as equity owners by comfirming',
			back: 'entityAccreditationVerify',
			settings: [
				{
					id: 21,
					type: 'radio',
					key: 'commonUploadDocuments',
					label:
						'It is a bank, In which Describe Entity As Equity Owner  and loan association or credit union, insurance company, registered investment company, registered business development company, licensed small business investment company, or employee benefit plan within the meaning of Title 1 of ERISA whose plan fiduciary is either a bank, insurance company or registered investment advisor or whose total assets exceed $5,000,000.',
				},
				{
					id: 22,
					type: 'radio',
					key: 'commonUploadDocuments',
					label:
						'It is a bank, savings and loan association or credit union, insurance company, registered investment company, registered business development company, licensed small business investment company, or employee benefit plan within the meaning of Title 1 of ERISA whose plan fiduciary is either a bank, insurance company or registered investment advisor or whose total assets exceed $5,000,000.',
				},
				{
					id: 28,
					type: 'button',
					key: 'commonUploadDocuments',
					label: 'Next',
				},
				{
					id: 29,
					type: 'button',
					key: 'commonUploadDocuments',
					label: 'Back',
				},
			],
		},
		describeEntityVerify: {
			label: 'Accreditation(506b)',
			key: 'accreditationVerify',
			title: 'Describe Entity Verify',
			subTitle:
				'I would like to verify  my entity as Describe Entity Verify by comfirming',
			back: 'entityAccreditationVerify',
			settings: [
				{
					id: 21,
					type: 'radio',
					key: 'commonUploadDocuments',
					label:
						'It is a bank, In which  Describe Entity  and loan association or credit union, insurance company, registered investment company, registered business development company, licensed small business investment company, or employee benefit plan within the meaning of Title 1 of ERISA whose plan fiduciary is either a bank, insurance company or registered investment advisor or whose total assets exceed $5,000,000.',
				},
				{
					id: 22,
					type: 'radio',
					key: 'commonUploadDocuments',
					label:
						'It is a bank, savings and loan association or credit union, insurance company, registered investment company, registered business development company, licensed small business investment company, or employee benefit plan within the meaning of Title 1 of ERISA whose plan fiduciary is either a bank, insurance company or registered investment advisor or whose total assets exceed $5,000,000.',
				},
				{
					id: 28,
					type: 'button',
					key: 'commonUploadDocuments',
					label: 'Next',
				},
				{
					id: 29,
					type: 'button',
					key: 'commonUploadDocuments',
					label: 'Back',
				},
			],
		},
		corporationPartnership: {
			label: 'Accreditation(506b)',
			key: 'accreditationVerify',
			title: 'Corporation Partnership',
			subTitle:
				'I would like to verify  my entity as Corporation Partnership investor by comfirming',
			back: 'entityAccreditationVerify',
			settings: [
				{
					id: 21,
					type: 'radio',
					key: 'corporationPartnershipUpload',
					label:
						'It is a corporation, partnership, business trust or a nonprofit organization within the meaning of Section 501(c)(3) of the Internal Revenue Code of 1986, as amended, that was not formed for the specific purpose of acquiring the securities offered and that has total assets in excess of $5,000,000.',
				},
				{
					id: 22,
					type: 'radio',
					key: 'corporationPartnershipUpload',
					label:
						'It is a bank, savings and loan association or credit union, insurance company, registered investment company, registered business development company, licensed small business investment company, or employee benefit plan within the meaning of Title 1 of ERISA whose plan fiduciary is either a bank, insurance company or registered investment advisor or whose total assets exceed $5,000,000.',
				},
				{
					id: 28,
					type: 'button',
					key: 'corporationPartnershipUpload',
					label: 'Next',
				},
				{
					id: 29,
					type: 'button',
					key: 'corporationPartnershipUpload',
					label: 'Back',
				},
			],
		},
		privateBusinessDevelopment: {
			label: 'Accreditation(506b)',
			key: 'accreditationVerify',
			title: 'Private Business Development',
			subTitle:
				'I would like to verify  my entity as Private Business Development investor by comfirming',
			back: 'entityAccreditationVerify',
			settings: [
				{
					id: 21,
					type: 'radio',
					key: 'privateBusinessDevelopmentUpload',
					label:
						'Private Business Development, business trust or a nonprofit organization within the meaning of Section 501(c)(3) of the Internal Revenue Code of 1986, as amended, that was not formed for the specific purpose of acquiring the securities offered and that has total assets in excess of $5,000,000.',
				},
				{
					id: 22,
					type: 'radio',
					key: 'privateBusinessDevelopmentUpload',
					label:
						'It is a bank, savings and loan association or credit union, insurance company, registered investment company, registered business development company, licensed small business investment company, or employee benefit plan within the meaning of Title 1 of ERISA whose plan fiduciary is either a bank, insurance company or registered investment advisor or whose total assets exceed $5,000,000.',
				},
				{
					id: 28,
					type: 'button',
					key: 'privateBusinessDevelopmentUpload',
					label: 'Next',
				},
				{
					id: 29,
					type: 'button',
					key: 'privateBusinessDevelopmentUpload',
					label: 'Back',
				},
			],
		},
		selfDirectedEmployee: {
			label: 'Accreditation(506b)',
			key: 'accreditationVerify',
			title: 'Self Directed Employee',
			subTitle:
				'I would like to verify  my entity as Self Directed Employee investor by comfirming',
			back: 'entityAccreditationVerify',
			settings: [
				{
					id: 21,
					type: 'radio',
					key: 'selfDirectedEmployeeUpload',
					label:
						'Self Directed, business trust or a nonprofit organization within the meaning of Section 501(c)(3) of the Internal Revenue Code of 1986, as amended, that was not formed for the specific purpose of acquiring the securities offered and that has total assets in excess of $5,000,000.',
				},
				{
					id: 22,
					type: 'radio',
					key: 'selfDirectedEmployeeUpload',
					label:
						'It is a bank, savings and loan association or credit union, insurance company, registered investment company, registered business development company, licensed small business investment company, or employee benefit plan within the meaning of Title 1 of ERISA whose plan fiduciary is either a bank, insurance company or registered investment advisor or whose total assets exceed $5,000,000.',
				},
				{
					id: 28,
					type: 'button',
					key: 'selfDirectedEmployeeUpload',
					label: 'Next',
				},
				{
					id: 29,
					type: 'button',
					key: 'selfDirectedEmployeeUpload',
					label: 'Back',
				},
			],
		},
		underSignedTrust: {
			label: 'Accreditation(506b)',
			key: 'accreditationVerify',
			title: 'UnderSigned Trust',
			subTitle:
				'I would like to verify  my entity as UnderSigned Trust investor by comfirming',
			back: 'entityAccreditationVerify',
			settings: [
				{
					id: 21,
					type: 'radio',
					key: 'underSignedTrustUpload',
					label:
						'Under Signed Trust organization within the meaning of Section 501(c)(3) of the Internal Revenue Code of 1986, as amended, that was not formed for the specific purpose of acquiring the securities offered and that has total assets in excess of $5,000,000.',
				},
				{
					id: 22,
					type: 'radio',
					key: 'underSignedTrustUpload',
					label:
						'It is a bank, savings and loan association or credit union, insurance company, registered investment company, registered business development company, licensed small business investment company, or employee benefit plan within the meaning of Title 1 of ERISA whose plan fiduciary is either a bank, insurance company or registered investment advisor or whose total assets exceed $5,000,000.',
				},
				{
					id: 28,
					type: 'button',
					key: 'underSignedTrustUpload',
					label: 'Next',
				},
				{
					id: 29,
					type: 'button',
					key: 'underSignedTrustUpload',
					label: 'Back',
				},
			],
		},
		underSignedTrustUpload: {
			label: 'Accreditation(506c)',
			key: 'accreditationVerify',
			title: 'Upload Documents',
			subTitle: 'Please upload all the relevant documents here.',
			back: 'underSignedTrust',
			settings: [
				{
					id: 17,
					type: 'uploadDocuments',
				},
			],
		},
		selfDirectedEmployeeUpload: {
			label: 'Accreditation(506c)',
			key: 'accreditationVerify',
			title: 'Upload Documents',
			subTitle: 'Please upload all the relevant documents here.',
			back: 'selfDirectedEmployee',
			settings: [
				{
					id: 17,
					type: 'uploadDocuments',
				},
			],
		},
		privateBusinessDevelopmentUpload: {
			label: 'Accreditation(506c)',
			key: 'accreditationVerify',
			title: 'Upload Documents',
			subTitle: 'Please upload all the relevant documents here.',
			back: 'privateBusinessDevelopment',
			settings: [
				{
					id: 17,
					type: 'uploadDocuments',
				},
			],
		},
		corporationPartnershipUpload: {
			label: 'Accreditation(506c)',
			key: 'accreditationVerify',
			title: 'Upload Documents',
			subTitle: 'Please upload all the relevant documents here.',
			back: 'corporationPartnership',
			settings: [
				{
					id: 17,
					type: 'uploadDocuments',
				},
			],
		},
		entityCommonUpload: {
			label: 'Accreditation(506c)',
			key: 'accreditationVerify',
			title: 'Upload Documents',
			subTitle: 'Please upload all the relevant documents here.',
			back: 'entityAccreditationVerify',
			settings: [
				{
					id: 17,
					type: 'uploadDocuments',
				},
			],
		},
	},
});

export const FinraUploadState = atom<any>({
	key: 'finra-upload-state',
	default: {},
});

export const AccredUploadFileState = atom<IUploadFile[]>({
	key: 'finra-upload-file-state',
	default: [],
});

export const AccredQueAndAns = atom<any>({
	key: 'accred-ques-ans',
	default: [],
});

export const EntityAccredFormState = atom<any>({
	key: 'entity-accred-form',
	default: {},
});

export const AccredSelectedKeyState = atom({
	key: 'accred-selected-key',
	default: 'selectOption',
});

export const SelectedOptionState = atom<string>({
	key: 'selected-option-state-key',
	default: '1',
});

export const SelectedUploadOptionKeyState = atom<string>({
	key: 'selected-upload-option-key-state-key',
	default: '',
});
