export type ILogoTypes =
	| 'default'
	| 'Navy Federal Credit Union'
	| 'Fidelity'
	| 'Wealthfront'
	| 'TD Bank'
	| 'Citibank Online'
	| 'Citizens Bank'
	| 'Chase'
	| 'Bank of America'
	| 'USAA'
	| 'Regions Bank'
	| 'Charles Schwab'
	| 'American Express'
	| 'Digital Federal Credit Union'
	| 'Wells Fargo'
	| 'Marcus by Goldman Sachs'
	| 'Mercury';
type IGetLogo = {
	[key in ILogoTypes]: string;
};

export const getLogoss: IGetLogo = {
	default: 'Bank_Default.svg',
	'Navy Federal Credit Union': 'Navy_Federal_Credit_Union.svg',
	Fidelity: 'Fidelity.svg',
	Wealthfront: 'Wealthfront.svg',
	'TD Bank': 'TD.svg',
	'Citibank Online': 'Citi.svg',
	'Citizens Bank': 'Citizens.svg',
	Chase: 'Chase.svg',
	'Bank of America': 'Bank_of_America.svg',
	USAA: 'USAA.svg',
	'Regions Bank': 'Regions_Financial_Corporation.svg',
	'Charles Schwab': 'Charles_Schwab_Corporation.svg',
	'American Express': 'American_Express.svg',
	'Digital Federal Credit Union': 'DigitalFederal_Credit_Union.svg',
	'Wells Fargo': 'Wells_Fargo.svg',
	'Marcus by Goldman Sachs': 'Marcus_By_Goldman_Sachs.svg',
	Mercury: 'Mercury.svg',
};

const getAllLogos = (): IGetLogo => {
	const allLogo = {};
	for (const key in getLogoss) {
		Object.assign(allLogo, {
			[key]: `${getLogoss[key as ILogoTypes]}`,
		});
	}
	return allLogo as IGetLogo;
};
export const getLogos = getAllLogos();

export const MESSAGE = {
	LINKING_ACCOUNT: 'Please wait we are linking your account.',
	FETCHING_BANK_DETAILS: 'Please wait we are fetching your bank details.',
};

export enum SESSION_TYPE {
	Seller = 'seller',
	Buyer = 'buyer',
}

export const CONNECT_BANK = {
	seller: {
		headerTitle: 'Connect your bank account to receive funds',
		headerSubtitle:
			'To receive funds seamlessly, please connect your preferred bank account',
	},
	buyer: {
		headerTitle: 'Connect your bank account to fund this account',
		headerSubtitle: 'You can fund this account from your multiple accounts.',
	},
};

export const ACCOUNTS = {
	seller: {
		summary:
			'Please take a moment and review your selected bank account information',
		bankTitle: 'Bank Account',
		btnLabel: 'Submit',
	},
	buyer: {
		summary: 'Please take a moment and review',
		bankTitle: 'Transferring From',
		btnLabel: 'Fund Account',
	},
};

export const DEFAULT_AMOUNT_FOR_SELLER = 0.6;
export const MIN_DEFAULT_AMOUNT = 0.5;
export const MAX_DEFAULT_AMOUNT = 1000000;
