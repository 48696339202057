import { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import Geocode from 'react-geocode';

import { locationCords } from './stores/states';
import { useNotification } from 'hooks/notification';

/* 
 This hooks help us to get user Location info 
 */
export const useLocation = () => {
	const [locationInfo, setLocationInfo] = useRecoilState(locationCords);
	const { errorNotification } = useNotification();
	const fetchLocation = useCallback(() => {
		return new Promise(resolve => {
			navigator.geolocation.getCurrentPosition(
				info => {
					/*
				  setting only few values as Other is not required  if coords is available
				*/
					if (info?.coords) {
						setLocationInfo({
							accuracy: info.coords.accuracy,
							altitude: info.coords.altitude,
							altitudeAccuracy: info.coords.altitudeAccuracy,
							latitude: info.coords.latitude,
							longitude: info.coords.longitude,
						});
						resolve({
							accuracy: info.coords.accuracy,
							altitude: info.coords.altitude,
							altitudeAccuracy: info.coords.altitudeAccuracy,
							latitude: info.coords.latitude,
							longitude: info.coords.longitude,
						});
					}
				},
				// eslint-disable-next-line @typescript-eslint/no-empty-function
				() => {
					resolve(false);
				},
				{ enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 }
			);
		});
	}, [setLocationInfo]);
	/*
      Calling this method only if locationinfo does not contain anything ,    
    */
	if (!locationInfo) fetchLocation();

	/* 
    Do not need to return method for that reason we are returing state that we have saved on line 7
    */

	const checkLocationPermission = useCallback(async () => {
		const { state } = await navigator.permissions.query({
			name: 'geolocation',
		});
		return state === 'denied' ? false : true;
	}, []);

	const getAddress = useCallback(
		async (onSuccess?: any, locationType: boolean = true) => {
			const checkPermission = await checkLocationPermission();
			if (!checkPermission) {
				errorNotification(
					'Please enable location permissions to use the "Locate Me" feature!'
				);
				return;
			}
			const locationData: any = await fetchLocation();
			Geocode.setApiKey('AIzaSyCTIQp7ZqdF6ls1ze3r9nwXV4lrVWEoH - 8');
			let payload;

			// set response language. Defaults to english.
			Geocode.setLanguage('en');

			// set response region. Its optional.
			// A Geocoding request with region=es (Spain) will return the Spanish city.
			Geocode.setRegion('es');

			// set location_type filter . Its optional.
			// google geocoder returns more that one address for given lat/lng.
			// In some case we need one address as response for which google itself provides a location_type filter.
			// So we can easily parse the result for fetching address components
			// ROOFTOP, RANGE_INTERPOLATED, GEOMETRIC_CENTER, APPROXIMATE are the accepted values.
			// And according to the below google docs in description, ROOFTOP param returns the most accurate result.
			Geocode.setLocationType(locationType ? 'ROOFTOP' : '');

			// Enable or disable logs. Its optional.
			Geocode.enableDebug();

			// Get formatted address, city, state, country from latitude & longitude when
			// Geocode.setLocationType("ROOFTOP") enabled
			// the below parser will work for most of the countries
			Geocode.fromLatLng(
				locationData?.latitude?.toString(),
				locationData?.longitude?.toString()
			).then(
				response => {
					let city,
						state,
						country,
						postal_code,
						route,
						sublocality,
						village,
						countryShortName;
					if (!response?.results?.length) {
						errorNotification(
							'Something went wrong. Please enter your address manually.'
						);
					}
					const address = response.results[0].address_components;
					for (let i = 0; i < address.length; i++) {
						for (let j = 0; j < address[i].types.length; j++) {
							switch (address[i].types[j]) {
								case 'route':
									route = address[i].long_name;
									break;
								case 'sublocality':
									sublocality = address[i].long_name;
									break;
								case 'neighborhood':
									village = address[i].long_name;
									break;
								case 'locality':
									city = address[i]?.long_name;
									break;
								case 'administrative_area_level_1':
									state = address[i].long_name;
									break;
								case 'country':
									country = address[i]?.long_name;
									countryShortName = address[i]?.short_name;

									break;
								case 'postal_code':
									postal_code = address[i]?.long_name;
									break;
							}
						}
					}

					payload = {
						city,
						state,
						country,
						postal_code,
						sublocality,
						route,
						village,
						countryShortName,
					};

					onSuccess?.(payload);

					return payload;
				},
				error => {
					// eslint-disable-next-line no-console
					console.error(error);
					if (/ZERO_RESULTS/.test(error)) {
						getAddress(onSuccess, false);
						return;
					}
					errorNotification(
						'Something went wrong. Please enter your address manually.'
					);
				}
			);

			return payload;
		},
		[checkLocationPermission, errorNotification, fetchLocation]
	);

	return { locationInfo, fetchLocation, getAddress };
};
